import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import { connect } from 'react-redux';

import Button from 'reactstrap/lib/Button';
import Media from 'reactstrap/lib/Media';
import Modal from 'reactstrap/lib/Modal';
import ModalHeader from 'reactstrap/lib/ModalHeader';
import ModalBody from 'reactstrap/lib/ModalBody';
import ModalFooter from 'reactstrap/lib/ModalFooter';

import closeImg from '../../../images/icons/close.png';

import { setCheckedVersion } from '../../../actions/member';

import {
  FONT_MODE, NEW_VERSION,
} from '../../../constants/constants';

import config from '../../../constants/config';

const isInAppFrame = config.isInAppFrame();

class NewVersion extends React.Component {
  static propTypes = {
    newVersion: PropTypes.number,
    t: PropTypes.func.isRequired,
    setCheckedVersionFunc: PropTypes.func.isRequired,
    fontMode: PropTypes.string,
    openChangeLogVersionModal: PropTypes.bool,
    closeTriggerChangeLogModal: PropTypes.func.isRequired,
    hidden: PropTypes.bool,
  }

  static defaultProps = {
    newVersion: null,
    fontMode: FONT_MODE.normal,
    openChangeLogVersionModal: false,
    hidden: false,
  }

  constructor(props) {
    super(props);
    this.state = {
      version: NEW_VERSION || '3.9.1',
    };
  }

  closeModal = () => {
    const { closeTriggerChangeLogModal, setCheckedVersionFunc } = this.props;
    const { version } = this.state;
    closeTriggerChangeLogModal();
    setCheckedVersionFunc(version);
  }

  render() {
    const { newVersion, t, i18n, fontMode, setCheckedVersionFunc, openChangeLogVersionModal, hidden } = this.props;
    const { version } = this.state;

    console.log('newVersion modal', openChangeLogVersionModal, newVersion, version);

    if (hidden) {
      return null;
    }

    if ((!newVersion || newVersion >= version) && !openChangeLogVersionModal) {
      if (!newVersion) {
        console.log('setCheckedVersionFunc');
        setCheckedVersionFunc(version);
      }
      return null;
    }

    return (
      <Modal container={'div > div'} isOpen size="lg" className={`${isInAppFrame ? 'modal-is-app-frame' : ''} notification new-version-modal old-modal root-font-${fontMode}`}>
        <ModalHeader
          toggle={this.closeModal}
          className="notification-header"
          close={
            <Media src={closeImg} className="notification-header-close" alt="X" onClick={this.closeModal} />
            }
        />
        <ModalBody className="notification-body">
          <div className="new-version-modal-scrollarea">
            {(!i18n.language || i18n.language === 'lv') ? (
              <>
                <h2 className="new-version-header">Sveiki, zolmaņi!</h2>
                <p className="new-version-body">Pēdējā laikā esam strādājuši pie kārtējā spēles atjauninājuma, kurā ir iekļauti šādi jaunumi un labojumi:</p>
                <ul className="new-version-body">
                  <li> Novērstas vairākas ar turnīru norisi saistītas problēmas.</li>
                  <li> Izlabotas dažas reklāmas baneru attēlošanas nepilnības.</li>
                  <li> Reklāmu banerus tagad var aizvērt ar pogu X, kā arī veikalā ir iespējams iegādāties reklāmu pastāvīgu slēpšanu.</li>
                  <li> Turnīru vēsturē iespējams izvēlēties rādīt tikai turnīrus, kur spēlētājs pats ir piedalījies.</li>
                  <li> Ieviesta iespēja redzēt spēlētājus, kuri mani ignorē.</li>
                  <li> Uzlabota automātiskā rupjību cenzēšana čatos.</li>
                  <li> Pievienoti 5 jauni sasniegumi, kā arī novērstas problēmas ar atlīdzību piešķiršanu par atsevišķiem sasniegumiem.</li>
                  <li> Ieviesta iespēja automātiski pievienoties istabai ar tādiem pašiem parametriem kā paša izveidotā spēles istaba.</li>
                  <li> Uzlabota informācija par turnīriem sadaļā 'Mans Info'.</li>
                  <li> Ja spēlētāja konti ir atzīmēti kā multi konti, tie vairs nevar spēlēt kopā vienā istabā.</li>
                  <li> Uzlabots informācijas baneris par aktīviem turnīriem.</li>
                  <li> Pielabotas vairākas problēmas ar spēles vērtēšanas logu.</li>
                  <li> Maksa par pievienošanos istabai tiek iekasēta pievienošanās brīdī, lai samazinātu pamesto istabu skaitu.</li>
                  <li> Iestatījumos ieviesta opcija nerādīt istabas, kurās ir kāds ignorēts spēlētājs.</li>
                </ul>

                <p className="new-version-body">Tālākā spēles izstrādē esam ieplānojuši ieviest šādus (un ne tikai!) papildinājumus:</p>
                <ul className="new-version-body">
                  <li> Iespēju mainīt autorizācijas metodi starp sociālo tīklu pasēm un e-pastu/paroli.</li>
                  <li> Pievienot istabas veidu bez laika ierobežojuma gājiena veikšanai.</li>
                  <li> Papildināt spēles palīdzības saturu.</li>
                  <li> Uzlabot puļu attēlošanu istabas informācijā.</li>
                </ul>
                <p className="new-version-body">Tāpat arī spēles WEB versijā (<a className="new-version-link" href="https://spelezoli.lv/" target="_blank" rel="noopener noreferrer">https://spelezoli.lv/</a>) ir iespējams iepazīties ar izstrādes stadijā esošo jauno spēles dizainu - droši varat izteikt atsauksmes un ierosinājumus.</p>
                <p className="new-version-body">Jūsu Zoles administrācija</p>
              </>
            ) : (null)}


            {(i18n.language === 'en') ? (
              <>
                <h2 className="new-version-header">Hello players!</h2>

                <p className="new-version-body">We've recently been working on a regular update to the game that includes the following news and fixes:</p>
                <ul className="new-version-body">
                  <li> Fixed some issues related to tournament organization.</li>
                  <li> Some issues with displaying advertising banners have been fixed.</li>
                  <li> Banner ads can now be closed with X button, and permanent ad hiding can be purchased in the Shop.</li>
                  <li> In the history of tournaments, it is possible to choose to show only tournaments where the player has participated.</li>
                  <li> Added the ability to see players who are ignoring me.</li>
                  <li> Improved automatic profanity censoring in chat.</li>
                  <li> Added 5 new achievements as well as fixed issues with rewards for some achievements.</li>
                  <li> Added the option to automatically join your own newly created game room to another room with the same parameters.</li>
                  <li> Improved information about tournaments in 'My Info' section.</li>
                  <li> If player accounts are marked as multi-accounts, they can no longer play together in the same room.</li>
                  <li> Info banner for active tournaments has been improved.</li>
                  <li> Fixed several issues of the Feedback window.</li>
                  <li> Room joining fee is charged at the moment of join to reduce the number of leaving rooms.</li>
                  <li> Added the option to hide rooms with ignored players in the settings.</li>
                </ul>

                <p className="new-version-body">In the further development of the game, we have planned to introduce the following (and not only!) improvements:</p>
                <ul className="new-version-body">
                  <li> Ability to change the authorization method between social login and email/password.</li>
                  <li> Add a new room type with no time limit on movement.</li>
                  <li> Add Game Help content.</li>
                  <li> Improve the display of pules in room information</li>
                </ul>
                <p className="new-version-body">Also in the WEB version of the game (<a className="new-version-link" href="https://spelezoli.lv/" target="_blank" rel="noopener noreferrer">https://spelezoli.lv/</a>)  you can get acquainted with the new design of the game. We ask you to leave your feedback and suggestions.</p>
                <p className="new-version-body">Your Zole Administration</p>

              </>
            ) : (null)}



            {(i18n.language === 'ru') ? (
              <>
                <h2 className="new-version-header">Здравствуйте, уважаемый игрок!</h2>

                <p className="new-version-body">Недавно мы закончили работу над очередным обновлением игры, которое включает в себя следующие улучшения и исправления:</p>
                <ul className="new-version-body">
                  <li> Исправлены некоторые проблемы, связанные с организацией турниров.</li>
                  <li> Устранены некоторые недостатки отображения рекламных баннеров.</li>
                  <li> Рекламу теперь можно закрыть с помощью кнопки X, а постоянную блокировку рекламы можно приобрести в магазине.</li>
                  <li> В истории турниров можно выбрать отображение только тех турниров, в которых принимал участие сам игрок.</li>
                  <li> Добавлена ​​возможность видеть игроков, которые меня заблокировали.</li>
                  <li> Улучшено автоматическое цензурирование ненормативной лексики в чате.</li>
                  <li> Добавлено 5 новых достижений, а также исправлены проблемы с выдачей вознаграждения за отдельные достижения.</li>
                  <li> Добавлена ​​возможность автоматического присоединения собственной новосозданной комнаты к другой игровой комнате с теми же параметрами.</li>
                  <li> Улучшено отображение информации о турнирах в разделе «Мой Инфо».</li>
                  <li> Если учетные записи игрока помечены как мультиаккаунты, они больше не смогут играть вместе в одной комнате.</li>
                  <li> Улучшен инфо баннер активных турниров.</li>
                  <li> Исправлены некоторые проблемы окна оценки игры.</li>
                  <li> Плата за присоединение к комнате взимается в момент присоединения, чтобы уменьшить количество брошенных комнат.</li>
                  <li> В настройках добавлена ​​возможность скрыть комнаты с игнорируемым игроком.</li>
                </ul>

                <p className="new-version-body">В дальнейшем развитии игры мы запланировали внести следующие (и не только эти!) дополнения:</p>
                <ul className="new-version-body">
                  <li> Возможность менять метод авторизации между входом через социальные сети и адресом электронной почты/паролем.</li>
                  <li> Добавить тип комнаты без временного ограничения на ход.</li>
                  <li> Дополнить Помощь Игры.</li>
                  <li> Улучшить отображение пуль в информации комнаты.</li>
                </ul>

                <p className="new-version-body">Также в WEB-версии игры (<a className="new-version-link" href="https://spelezoli.lv/" target="_blank" rel="noopener noreferrer">https://spelezoli.lv/</a> можно ознакомиться с новым дизайном игры. Просим оставить свои отзывы и предложения.</p>
                <p className="new-version-body">Ваша администрация</p>
              </>
            ) : (null)}
          </div>
        </ModalBody>
        <ModalFooter className="notification-footer">
          <Button color="link" className="notification-footer-button" onClick={this.closeModal}>{t('common.ok')}</Button>
        </ModalFooter>
      </Modal>
    );
  }
}

const mapStateToProps = state => ({
  newVersion: state.member.newVersion || null,
});

const mapDispatchToProps = {
  setCheckedVersionFunc: setCheckedVersion,
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('common')(NewVersion));
