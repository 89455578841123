import React from 'react';
import PropTypes, { func } from 'prop-types';
import { withTranslation } from 'react-i18next';

import { connect } from 'react-redux';
// import ReactStars from "react-rating-stars-component";
import { size, includes, filter, map, sumBy, countBy, flatMap, values } from 'lodash';
import { TextInput } from '../../Components/Components/TextInput';

import {
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  Label,
  Alert,
  Input,
  Button,
  FormGroup,
  Media,
  Progress
} from 'reactstrap';
import moment from 'moment';

import momentLocalizer from 'react-widgets-moment';
import InlineModal from '../../Components/Components/InlineModal';
import CustomModal from '../../Components/Components/Modal';

import * as constants from '../../../../../constants/constants';
import StarRating from '../../Components/Components/StarRating';

import {
  checkFeedbackModal,
  feedbackSubmit,
  feedbackClose,
  getSurvey,
  answerForSurveyQuestion,
  checkedIsSuveyFunc,
  getFeedback
} from '../../../../../actions/member';
import config from '../../../../../constants/config';

import checkAction from '../../../../../images/icons/checkAction.svg';
import closeImg from '../../../../../images/icons/close.png';
import Toggle from '../../Components/Components/Toggle';
import { getCurrentPlatform } from '../../../../../utils/environment';
import ErrorNotification from '../../Components/Components/ErrorNotification';

class Feedback extends React.Component {
    static propTypes = {
      t: PropTypes.func.isRequired,
      screenMode: PropTypes.string,
      run: PropTypes.bool,
      feedbacks: PropTypes.shape([]),
      surveys: PropTypes.shape([]),
      fetchFeedback: PropTypes.func.isRequired,
      fetchSurvey: PropTypes.func.isRequired,
      fetchFeedbackSubmit: PropTypes.func.isRequired,
      submitAnswerForSurveyQuestion: PropTypes.func.isRequired,
    }

    static defaultProps = {
      screenMode: constants.SCREEN_MODE.dark,
      run: false,
      feedbacks: [],
      surveys: [],
      userIsSurvey: true,
    }

    constructor(props) {
      super(props);
      this.state = {
        additionalComment: '',
        feedbackComment: '',
        loading: false,
        rating: 0,
        status: null,
        error: null,
        alertVisible: false,
        modalOpen: true,
        limitCount: 0,
        successFeedback: false,
        answerList: [],
        anotherAnswerOption: '',
        checkedQuestionAnswer: [],
        surveyIsOpen: true,
        surveyQuestionType: null,
        successSurvey: false,
        allowAnswerOption: false,
        surveyResults: {},
        platform: 'dWeb',
        curDate: Date.now(),
      };
    }

    componentDidMount() {
      const { fetchFeedback, fetchSurvey } = this.props;
      const { hostname } = window.location;

      let devMode = false;

      if (hostname && (hostname.includes('dev') || hostname.includes('localhost'))) {
        devMode = true;
      }

      const isInDraugiemAppFrame = config.isInDraugiemAppFrame();
      const isInFacebooxAppFrame = config.isInFacebookAppFrame();
      const isInInboxAppFrame = config.isInInboxAppFrame();
      let screen = 'dWeb'
      if (window.innerWidth <= 1280) {
        screen = 'mWeb'
      } else if (isInDraugiemAppFrame) {
        screen = constants.DRAUGIEM_PLATFORM
      } else if (isInFacebooxAppFrame) {
        screen = constants.FACEBOOK_PLATFORM
      } else if (isInInboxAppFrame) {
        screen = constants.INBOX_PLATFORM
      }

      this.setState({
        devMode,
        platform: screen
      })

      fetchFeedback(devMode, constants.NEW_DESIGN, screen);
      fetchSurvey(devMode);

      this.intervalID = setInterval(() => {
        this.setState({
          curDate: Date.now(),
        })
      }, 1000);
    }

    componentWillUnmount() {
      clearInterval(this.intervalID);
    }

    handleResize = () => {
      const { fetchFeedback } = this.props;
      const { platform, devMode } = this.state;
  
      const isInDraugiemAppFrame = config.isInDraugiemAppFrame();
      const isInFacebooxAppFrame = config.isInFacebookAppFrame();
      const isInInboxAppFrame = config.isInInboxAppFrame();
  
      let currentPlatform = platform;
   
      if (!isInDraugiemAppFrame && !isInFacebooxAppFrame && !isInInboxAppFrame) {
        if (window.innerWidth > 1280) {
          if (currentPlatform !== constants.DWEB_PLATFORM) {
            fetchFeedback(devMode, constants.NEW_DESIGN, constants.DWEB_PLATFORM);
            this.setState({
              platform: constants.DWEB_PLATFORM,
            })
          }
        } else if (window.innerWidth <= 1280) {
          if (currentPlatform !== constants.MWEB_PLATFORM) {
            fetchFeedback(devMode, constants.NEW_DESIGN, constants.MWEB_PLATFORM);
            this.setState({
              platform: constants.MWEB_PLATFORM,
            })
          }
        }
      }
    };

    handleChange = (e) => {
      const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;

      this.setState({
        [e.target.name]: value,
        limitCount: value.length,
      });
    }

    handleRating = (rating) => {
      this.setState({
        rating,
      });
    }

    handleFeedback = (feedbackId) => {
      const { t, fetchFeedbackSubmit, name, firstName, lastName, fetchFeedback } = this.props;
      const { rating, feedbackComment, platform, devMode } = this.state;
      let userName = '';
      if (rating === 0) {
        this.setState({
          error: t('common.ratingWrong'),
          status: constants.FROUNT_SIDE_STATUS.danger,
          alertVisible: true,
        });
        setTimeout(() => {
          this.setState({
            alertVisible: false,
            status: null,
            error: null,
          });
        }, 10000);
        return ;
      }
      if (!feedbackComment || feedbackComment === '' || feedbackComment.trim().length === 0) {
        this.setState({
          error: t('common.wrongComment'),
          status: constants.FROUNT_SIDE_STATUS.danger,
          alertVisible: true,
        });
        setTimeout(() => {
          this.setState({
            alertVisible: false,
            status: null,
            error: null,
          });
        }, 10000);
        return ;
      }
      userName = name ? name : firstName + lastName;
      fetchFeedbackSubmit(rating, feedbackComment, userName, feedbackId, constants.NEW_DESIGN, platform).then(res => {
        this.setState({
          successFeedback: true,
          modalOpen: false,
        });
        fetchFeedback(devMode, constants.NEW_DESIGN, platform);
      }).catch(e => {
        this.setState({
          error: t('common.wrongComment'),
          status: constants.FROUNT_SIDE_STATUS.danger,
          alertVisible: true,
        });
        setTimeout(() => {
          this.setState({
            alertVisible: false,
            status: null,
            error: null,
          });
        }, 10000);
      });
    }

    closeModal = () => {
      const { feedbackCloseFunc, fetchSurvey } = this.props;
      const { devMode } = this.state;
      feedbackCloseFunc();

      this.setState({
        modalOpen: false,
        successFeedback: false,
      });

      fetchSurvey(devMode);
    }

    toggleRedeemGift = () => {
      this.setState({
        successFeedback: false,
        modalOpen: false,
      });
    }

    handleAnotherAnswer = (value, type) => {
      this.setState({
        anotherAnswerOption: value,
      })
    }

    handleAllowAnswerChange = (e) => {
      const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
      this.setState({
        [e.target.name]: value,
      });
    }
  
    handleCheckedQuestionAnswer = (item, type) => {
      const { checkedQuestionAnswer } = this.state;

      let temp = [...checkedQuestionAnswer];
      console.log(type, item, "checkType")
      if (type === 'type2') {
        temp = [];
        const index = temp.indexOf(item);
        if (index === -1) {
          temp.push(item);
        }
      } else {
        const index = temp.indexOf(item);
        if (index === -1) {
          temp.push(item);
        } else {
          temp.splice(index, 1);
        }
      }
  
      this.setState({
        checkedQuestionAnswer: temp,
      });
    }
  
    handleSurveySubmit = (surveyKey, questionType) => {
      const { submitAnswerForSurveyQuestion, surveys, name, firstName, lastName, t } = this.props;
      const { checkedQuestionAnswer, additionalComment, anotherAnswerOption, answerList } = this.state;
      if (!surveyKey) return;

      if (questionType === constants.SURVEY_TYPE[2].id && checkedQuestionAnswer.length === 0) {
        this.setState({
          error: t('common.noChoice'),
          status: constants.FROUNT_SIDE_STATUS.danger,
          alertVisible: true,
        });
        setTimeout(() => {
          this.setState({
            alertVisible: false,
            status: null,
            error: null,
          });
        }, 10000);
        return;
      }

      if (questionType === constants.SURVEY_TYPE[0].id && (!additionalComment || additionalComment === '' || additionalComment.trim().length === 0)) {
        this.setState({
          error: t('common.noAnswer'),
          status: constants.FROUNT_SIDE_STATUS.danger,
          alertVisible: true,
        });
        setTimeout(() => {
          this.setState({
            alertVisible: false,
            status: null,
            error: null,
          });
        }, 10000);
        return;
      }
      // if (questionType === constants.SURVEY_TYPE[0].id && !additionalComment) return;
      if (questionType === constants.SURVEY_TYPE[1].id && size(checkedQuestionAnswer) === 0) return;
      if (questionType === constants.SURVEY_TYPE[2].id && (size(checkedQuestionAnswer) === 0)) return;
      const userName = name ? name : firstName + lastName;
      
      if (questionType !== constants.SURVEY_TYPE[0].id) {
        this.setState({
          answerList: [...surveys[0].answerList],
        })
      }
  
      const data = {
        surveyKey,
        questionType,
        userName,
        checkedQuestionAnswer,
        additionalComment,
        // anotherAnswerOption,
        design: 'new'
      };
      submitAnswerForSurveyQuestion(data).then(res => {
        if (res.status === constants.FROUNT_SIDE_STATUS.success ) {
          this.setState({
            successSurvey: true,
            // surveyIsOpen: false,
            surveyResults: res.data,
            surveyQuestionType: questionType,
          })
        }
      });
    }
  
    closeSurvey = () => {
      const { fetchSurvey } = this.props;
      const { devMode } = this.state;
      this.setState({
        surveyQuestionType: null,
        checkedQuestionAnswer: [],
        successSurvey: false,
        surveyIsOpen: false,
      })
      fetchSurvey(devMode);
    }

    handleAllowAnswer = () => {
      this.setState(prevState => ({
        allowAnswerOption: !prevState.allowAnswerOption, 
      }));
    }

    calculateSurveyResult = (type, answerLen) => {
      const { surveyResults } = this.state;

      const totalSubmittedType = sumBy(values(surveyResults), item => size(item.checkedQuestionAnswer));
      const valueCounts = countBy(flatMap(values(surveyResults), 'checkedQuestionAnswer'));

      return Math.floor((((valueCounts[type] || 0) / totalSubmittedType) * 100) * 100) / 100;
    }

    render() {
      const {
        t,
        screenMode,
        run,
        feedbacks,
        surveys,
      } = this.props;
      const {
        additionalComment,
        feedbackComment,
        loading,
        rating,
        status,
        error,
        alertVisible,
        modalOpen,
        limitCount,
        successFeedback,
        answerList,
        anotherAnswerOption,
        checkedQuestionAnswer,
        surveyIsOpen,
        surveyQuestionType,
        successSurvey,       
        allowAnswerOption, 
        devMode,
        curDate
      } = this.state;
      let isSurveryEnv = false;
      let isFeedbackEnv = false;

      // const devMode = false;

      if (surveys[0]) {
        if (devMode && filter(surveys[0]?.selectedDesigns, (item) => item && item?.value === 'new')[0]) {
          isSurveryEnv = true;
        } else if (!devMode && !surveys[0].devMode && filter(surveys[0]?.selectedDesigns, (item) => item && item?.value === 'new')[0]) {
          isSurveryEnv = true;
        }
      }

      if (feedbacks[0]) {
        const currentPlatform = getCurrentPlatform(true);
        const platformAllowed = feedbacks[0].platforms.filter(platform => platform.value === currentPlatform).length > 0;
        const designAllowed = feedbacks[0].designs.filter(design => design.value === 'new').length > 0;
        isFeedbackEnv = platformAllowed && designAllowed;
      }

      return (
        <>
          <InlineModal
            isOpen={!run && feedbacks[0] && modalOpen && isFeedbackEnv}
            onButtonClose={this.closeModal}
            inlineClassName={!successFeedback ? 'feedback-new-section' : null}
            size="md"
            title={t('common.rateGame')}
            footer={null}
            screenMode={screenMode}
            body={(
              <>
                <div className="feedback-new-section-feedback-body">
                  <div className='feedback-new-section-feedback-body-rating-stars'>
                    <Label>{t('common.selectRate')}</Label>
                    <StarRating handleRating={this.handleRating} rating={rating} t={t} />
                  </div>
                  <div className='feedback-new-section-feedback-body-rating-comments-section'>
                    <div className='feedback-new-section-feedback-body-rating-comments'>
                      <Input
                        type="textarea"
                        name="feedbackComment"
                        id="feedbackComment"
                        maxLength={constants.FEEDBACK_LENGTH.toString()}
                        value={feedbackComment}
                        disabled={loading}
                        onChange={this.handleChange}
                        placeholder={t('common.additionalComment')}
                      />
                      {
                        alertVisible && (
                          <div className={`feedback-new-section-feedback-body-rating-comments-inputInlineMessage-${status}`}>
                            <p>{error}</p>
                          </div>
                        )
                      }
                      <Label className={constants.FEEDBACK_LENGTH - limitCount > 10 ? 'feedback-new-section-feedback-body-rating-comments-limit-characters' : 'feedback-new-section-feedback-body-rating-comments-limited-characters-error'}>{`${limitCount}/${constants.FEEDBACK_LENGTH}`}</Label>
                    </div>
                  </div>
                </div>
                <div className="feedback-new-section-feedback-footer">
                  <Button className="feedback-new-section-feedback-footer-submit-button" onClick={() => this.handleFeedback(feedbacks[0].key)}>{t('common.submit')}</Button>
                  <Button className="feedback-new-section-feedback-footer-close-button" onClick={this.closeModal}>{t('common.close')}</Button>
                </div>
              </>
            )}
          />
          <InlineModal
            isOpen={!run && size(feedbacks) === 0 && size(surveys) > 0 && surveyIsOpen && isSurveryEnv && (!surveys[0].isPlan || (surveys[0].isPlan && curDate >= surveys[0].startDate && curDate < surveys[0].endDate))}
            onButtonClose={this.closeSurvey}
            inlineClassName={'feedback-new-section'}
            size="md"
            title={t('common.survey')}
            footer={null}
            screenMode={screenMode}
            body={(
              !successSurvey ? (
                <>
                  <div className="feedback-new-section-feedback-body survey-modal-body-section">
                    <div className='feedback-new-section-feedback-body-rating-stars survey-header-new-section'>
                        <Label className='title'>{surveys[0]?.question}</Label>
                        <Label className='description'>{surveys[0]?.description}</Label>
                    </div>
                      {
                        surveys[0]?.questionType === constants.SURVEY_TYPE[0].id ? (
                          <div className='feedback-new-section-feedback-body-rating-comments-section survey-body-new-section'>
                            <div className='feedback-new-section-feedback-body-rating-comments'>
                              <Input
                                type="textarea"
                                name="additionalComment"
                                id="additionalComment"
                                maxLength={constants.FEEDBACK_LENGTH.toString()}
                                value={additionalComment}
                                disabled={loading}
                                onChange={this.handleChange}
                                placeholder={t('common.additionalComment')}
                              />
                              {
                                alertVisible && (
                                  <div className={`feedback-new-section-feedback-body-rating-comments-inputInlineMessage-${status}`}>
                                    <p>{error}</p>
                                  </div>
                                )
                              }
                              <Label className={constants.FEEDBACK_LENGTH - limitCount > 10 ? 'feedback-new-section-feedback-body-rating-comments-limit-characters' : 'feedback-new-section-feedback-body-rating-comments-limited-characters-error'}>{`${limitCount}/${constants.FEEDBACK_LENGTH}`}</Label>
                            </div>
                          </div>
                        ) : surveys[0]?.questionType === constants.SURVEY_TYPE[1].id ? (
                          <div className='feedback-new-section-feedback-body-rating-comments-section survey-body-question-answers'>
                            {surveys[0].answerList && surveys[0].answerList && map(surveys[0].answerList, (item) => (
                                <FormGroup>
                                  <Toggle checked={includes(checkedQuestionAnswer, item.id)} onChange={() => this.handleCheckedQuestionAnswer(item.id, surveys[0]?.questionType)} text={item.value} />
                                </FormGroup>
                              ))
                            }
                            {/* <FormGroup>
                              <Label>Allow Answer Comment</Label>
                              <Toggle checked={allowAnswerOption} onChange={() => this.handleAllowAnswer()} text="Allow Answer" />
                            </FormGroup>
                            <FormGroup>
                              <TextInput
                                contentEditable={allowAnswerOption}
                                width="100%"
                                inputType='anotherAnswerOption'
                                placeholder={t('common.anotherOption')}
                                initialValue={anotherAnswerOption}
                                onChange={(value, type) =>
                                  this.handleAnotherAnswer(value, type)
                                }
                              />
                            </FormGroup> */}
                          </div>
                        ) : (
                          <div className='feedback-new-section-feedback-body-rating-comments-section'>
                            {surveys[0]?.answerList && surveys[0]?.answerList && map(surveys[0]?.answerList, (item) => {
                                if (!item || item.value === '') return;

                                return (
                                  <>
                                  <FormGroup>
                                    <Toggle checked={includes(checkedQuestionAnswer, item.id)} onChange={() => this.handleCheckedQuestionAnswer(item.id, surveys[0]?.questionType)} text={item.value} />
                                  </FormGroup>
                                  </>
                                );
                              })
                            }
                            {
                              alertVisible && (
                                <ErrorNotification isVisible={alertVisible} text={error} isAlert={status === 'error'} />
                              )
                            }
                            {/* <FormGroup className='flex-column'>
                              <Label>Allow Answer Comment</Label>
                              <Toggle checked={allowAnswerOption} onChange={() => this.handleAllowAnswer()} text="Allow Answer" />
                            </FormGroup>
                            <FormGroup>
                              <TextInput
                                width="100%"
                                inputType='anotherAnswerOption'
                                placeholder={t('common.anotherOption')}
                                initialValue={anotherAnswerOption}
                                contentEditable={allowAnswerOption}
                                onChange={(value, type) =>
                                  this.handleAnotherAnswer(value, type)
                                }
                              />
                            </FormGroup> */}

                          </div>
                        )
                      }
                  </div>
                  <div className="feedback-new-section-feedback-footer">
                    <Button className="feedback-new-section-feedback-footer-submit-button" onClick={() => this.handleSurveySubmit(surveys[0].key, surveys[0].questionType)}>{t('common.submit')}</Button>
                  </div>
                </>
              ) : (
                <>
                  <div className="feedback-new-section-feedback-body survey-modal-body-section">
                    {(surveyQuestionType === 'type3' || surveyQuestionType === 'type2') && (
                      <div className='feedback-new-section-feedback-body-rating-stars survey-header-new-section'>
                        <Label className='title'>{surveys[0]?.question}</Label>
                        <Label className='description'>{surveys[0]?.description}</Label>
                      </div>
                    )}
                    {
                      surveyQuestionType === 'type1' ? (
                        <div className='feedback-new-section-feedback-body-rating-comments-section d-flex align-items-center flex-column'>
                          <Media src={checkAction} className="event-tree-svg-before mb-2" />
                          <Label>{t('common.sentSuccess')}</Label>
                        </div>
                      ) : (
                        <div className='feedback-new-section-feedback-body-rating-comments-section'>
                          {surveys[0].answerList && surveys[0].answerList && map(surveys[0].answerList, (item) => {
                              if (!item || item.value === '') return;

                              return (
                                <div className='d-flex flex-column mb-4'>
                                  <div className='w-full d-flex flex-row items-center justify-between'>
                                    <label>{item.value}</label>
                                    <label className="ml-1 my-info-statistics-data">{`${this.calculateSurveyResult(item.id, size(surveys[0].answerList))}%`}</label>
                                  </div>
                                  <div className='w-full'>
                                    <Progress
                                      color="link"
                                      value={this.calculateSurveyResult(item.id, size(surveys[0].answerList))}
                                      className="my-info-statistics-progressbar"
                                    />
                                  </div>
                                </div>
                              );
                            })
                          }
                        </div>
                      )
                    }
                  </div>
                  <div className="feedback-new-section-feedback-footer">
                    <Button className="feedback-new-section-feedback-footer-submit-button" onClick={this.closeSurvey}>{t('common.ok')}</Button>
                  </div>
                </>
              )
            )}
          />
          <CustomModal
            isOpen={successFeedback}
            onButtonClose={this.closeModal}
            size="md"
            title={t('common.rateGame')}
            footer={null}
            body={(
              <>
                <div className="feedback-new-section-complete-feedback-body">
                  <Media src={checkAction} className="event-tree-svg-before" />
                </div>
                <div className="feedback-new-section-complete-feedback-footer">
                  {t('common.completedFeedback')}
                </div>
              </>
            )}
          />
        </>
      );
    }
}

const mapStateToProps = state => ({
  name: state.member.name || '',
  firstName: state.member.firstName || '',
  lastName: state.member.lastName || '',
  feedbacks: state.member.feedbacks || [],
  surveys: state.member.surveys || [],
  surveyResults: state.member.surveyResults || {},
});

const mapDispatchToProps = {
  fetchFeedback: getFeedback,
  fetchSurvey: getSurvey,
  fetchFeedbackSubmit: feedbackSubmit,
  submitAnswerForSurveyQuestion: answerForSurveyQuestion,
  feedbackCloseFunc: feedbackClose
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('common')(Feedback));
