import React, { Fragment, PureComponent } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';

import Moment from 'react-moment';

import { withRouter } from 'react-router-dom';

import ReactGA from 'react-ga';

import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
import Media from 'reactstrap/lib/Media';
import Button from 'reactstrap/lib/Button';
import Modal from 'reactstrap/lib/Modal';
import ModalHeader from 'reactstrap/lib/ModalHeader';
import ModalBody from 'reactstrap/lib/ModalBody';
import ModalFooter from 'reactstrap/lib/ModalFooter';

import { withTranslation } from 'react-i18next';

import ScrollArea from 'react-scrollbar';
import ScoreTable from '../../../../containers/Game/ScoreTable';

import ScrollAreaButtons from '../../UI/ScrollAreaButtons';

import closeImg from '../../../../images/icons/close.png';
import leaderboardImg from '../../../../images/icons/leaderboard.svg';
import missedInfoImg from '../../../../images/Game/missedInfo.png';
// import defaultImage from '../../../../images/redesign/normal-mode/common/default_image.webp';

import { getBetValue } from '../../../../common/services/data-service';
import LeaveRoomPenalty from '../../Menu/RulesPage/Tabs/LeaveRoomPenalty';

import {
  getPartyLog, setRoomEnded, setTournamentRoomEnded
} from '../../../../actions/room';

import {
  FONT_MODE,
  sendLanguage,
} from '../../../../constants/constants';
import { renderTextWithLineBreaksAndSymbolColor, trimString } from '../../../../utils/stringUtils';
import { getCardImage } from '../../../../utils/cardInfoUtils';

// const EndResultModal = React.memo(({ roomId, leaveRoom, gameT, playButtonSound, closeReason, t }) => {

class EndResultModal extends PureComponent {
  static propTypes = {
    setRoomEndedFunction: PropTypes.func.isRequired,
    setTournamentRoomEndedFunction: PropTypes.func.isRequired,
    tournamentRoom: PropTypes.bool,
    setShowEndResultModal: PropTypes.func.isRequired,
    roomId: PropTypes.string,
    leaveRoom: PropTypes.func.isRequired,
    playButtonSound: PropTypes.func.isRequired,
    gameT: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
    fetchPartyLog: PropTypes.func.isRequired,
    partyLog: PropTypes.arrayOf(PropTypes.shape()),
    closeReason: PropTypes.shape(),
    players: PropTypes.shape(),
    bet: PropTypes.string,
    minGames: PropTypes.number,
    rPlayed: PropTypes.number,
    fourthPlayerMissed: PropTypes.bool,
    tournamentRoom: PropTypes.bool,
    notificationSettings: PropTypes.shape(),
    member: PropTypes.shape({
      uid: PropTypes.string,
    }),
    fontMode: PropTypes.string,
    fourPRoom: PropTypes.bool,
  };

  static defaultProps = {
    roomId: null,
    closeReason: {},
    players: {},
    member: {},
    notificationSettings: {},
    partyLog: [],
    bet: null,
    rPlayed: null,
    minGames: null,
    fourthPlayerMissed: false,
    tournamentRoom: false,
    fontMode: FONT_MODE.normal,
    fourPRoom: false,
  };

  constructor(props) {
    super(props);
    this.state = {
    //  error: null,
    //  roomId: '',
    };
  }


  componentDidMount() {
    const { leaveRoom, notificationSettings, setShowEndResultModal } = this.props;

    //if (notificationSettings && notificationSettings.showAtRoomEnd === false) {
    //  setShowEndResultModal(false);
    //}

    const {
      closeReason, bet, minGames, rPlayed,
    } = this.props;

    //  let betValue;
  }

  componentWillUnmount() {
  //  clearTimeout(this.timeoutID);
  }

  fetchPartyLog = (party) => {
    const { roomId, fetchPartyLog } = this.props;
    fetchPartyLog(roomId, party).then(() => {
      ReactGA.event({
        category: 'Game',
        action: 'Open Round Info',
      });

      this.setState({ showPartyModal: true }); // , activeParty: party
    });
  }

  closePartyModal = () => {
    this.setState({ showPartyModal: false }); // , activeParty: null
  }

  updateScrollPos = (val) => {
    this.setState({ scrollPos: val.topPosition || 0 });
  }

  scroll = (newVal) => {
    this.pointsScrollbar.scrollYTo(newVal);
  }

  showInfoModal = () => {
    this.setState(prevState => ({
      openMissedInfoModal: !prevState.openMissedInfoModal,
    }))
  }

  leaveRoom = () => {
    const { closeReason, leaveRoom, history, setRoomEndedFunction, setTournamentRoomEndedFunction, tournamentRoom } = this.props;

    console.log('call leaveRoom test');
    leaveRoom();

    console.log('call leaveRoom 1');

    if (closeReason) {
      console.log('history.push to leaveRoom 2222', { history: history.location });

      setRoomEndedFunction(true);
      if (tournamentRoom) {
        setTournamentRoomEndedFunction(true);
      }
      history.push('/');

      console.log('history.push to leaveRoom 2 2222', { history: history.location });
    }
  }

  setShowEndResultModal = (value) => {
    const { setShowEndResultModal } = this.props;

    console.log('setShowEndResultModal test');

    setShowEndResultModal(value);
    this.leaveRoom();
  }

  render = () => {
    const {
      fourPRoom, bet, minGames, rPlayed, players, roomId, leaveRoom, gameT, playButtonSound, closeReason, t, i18n, fourthPlayerMissed, member, partyLog, tournamentRoom, fontMode, tournamentRound, setShowEndResultModal,
    } = this.props;
    const {
      showPartyModal, scrollPos, openMissedInfoModal
    } = this.state;

    let secondBurriedCard = false;

    let sortedPartyLog = partyLog.sort(function(a, b){
      return  a?.time - b?.time
    });
    console.log('realPlayed',{rPlayed});
    
    let leavePenalty;
    let leaveReward;

    const betValue = getBetValue(bet);

    if (closeReason) {
      console.log(closeReason.isAutomated, closeReason.reason, "check auto missed" );
    }

    if (closeReason && closeReason.isAutomated) {
      leavePenalty = 0;
      leaveReward = 0;
    } else {
      leavePenalty = fourPRoom ? Math.round(betValue * 24) : Math.round(betValue * 16);
      leaveReward = Math.round(0.25 * leavePenalty);
    }

    if (closeReason && closeReason.reason === 'leftRoom') {
      if (minGames && (rPlayed || rPlayed === 0) && minGames > (rPlayed) && betValue) {
        leavePenalty += Math.round((minGames - rPlayed) * 10 * betValue);

        leaveReward += Math.round(0.25 * (minGames - rPlayed) * 10 * betValue);
      }
    } else if (minGames && (rPlayed || rPlayed === 0) && minGames > rPlayed && betValue) {
      leavePenalty += Math.round((minGames - rPlayed) * 10 * betValue);

      leaveReward += Math.round(0.25 * (minGames - rPlayed) * 10 * betValue);
    }

    /*  if (minGames && (rPlayed || rPlayed === 0) && minGames > (rPlayed + 1) && betValue) {
      leavePenalty += Math.round((minGames - rPlayed) * 10 * betValue);

      leaveReward += Math.round(0.25 * (minGames - rPlayed) * 10 * betValue);
    } */

    console.log(leavePenalty, leaveReward, closeReason, minGames, rPlayed, betValue, "***", tournamentRound, "closeReason****");
  
    return (
      <div>
        <Modal container={'div > div'} isOpen toggle={() => this.setShowEndResultModal(false)} className={`end-result-modal old-modal root-font-${fontMode}`}>
          <ModalHeader
            toggle={() => this.setShowEndResultModal(false)}
            className="end-result-modal-header"
            close={
              <Media src={closeImg} className="notification-header-close" alt="X" onClick={() => { playButtonSound(); this.setShowEndResultModal(false); }} />
            }
          >
            <Media className="end-result-modal-header-image" src={leaderboardImg} />
            <div className="end-result-modal-header-text">
              {' '}
              {gameT('results')}
              {' '}
            </div>
          </ModalHeader>
          <ModalBody className="end-result-modal-body">
            <ScoreTable
              roomId={roomId}
              scoreTableOpen
              isEndResultTable
              fetchPartyLog={this.fetchPartyLog}
              closeReason={closeReason}
              showInfoModal={this.showInfoModal}
            />
            {closeReason && (
              <Fragment>
                <div className="end-result-modal-close-info">
                  <div className='end-result-modal-close-info-image mr-3 mt-1'>
                    <Media src={missedInfoImg} />
                  </div>
                  {(!tournamentRoom && closeReason && closeReason.reason === 'lastRound') ? (
                    <div className="end-result-modal-close-reason">
                      {t('lastRound', { player: closeReason.playerName })}
                    </div>
                  ) : (null)}
                  {(tournamentRoom && closeReason && closeReason.reason === 'lastRound' && closeReason.playerUid === 'tournament') ? (
                    <>
                      {tournamentRound === 'last' ? (
                        <div className="end-result-modal-close-reason">
                          {t('lastRoundTournamentLast')}
                        </div>
                      ): (
                        <div className="end-result-modal-close-reason">
                            {t('lastRoundTournament', { round: tournamentRound || 1 })}
                        </div>
                      )}
                    </>
                  ) : (null)}
                  {(closeReason && closeReason.reason === 'missedTurn') ? (
                    <>
                      {closeReason.isAutomated ? (
                        <>
                          {closeReason.playerUid === member.uid ? (
                            <div className="end-result-modal-close-reason">
                              <>
                                {leavePenalty !== 0 ? (
                                  <>
                                    {t('missedTurnMessageAutomatedMe', { player: closeReason.playerName, amount: leavePenalty })}
                                  </>
                                ) : (
                                  <>
                                    {t('missedTurnMessageAutomatedMeZero', { player: closeReason.playerName })}
                                  </>
                                )}
                              </>
                            </div>
                          ) : (
                            <div className="end-result-modal-close-reason">
                              <>
                                {leaveReward !== 0 ? (
                                  <>
                                    {t('missedTurnMessageAutomatedOther', { player: closeReason.playerName, amount: leaveReward })}
                                  </>
                                ) : (
                                  <>
                                    {t('missedTurnMessageAutomatedOtherZero', { player: closeReason.playerName })}
                                  </>
                                )}
                              </>
                              {/*    {t('missedTurnMessageAutomatedOther', { player: closeReason.playerName, amount: leaveReward })}  */}
                            </div>
                          )}
                          {/*  <div className="end-result-modal-close-reason">
                        {t('missedTurnMessageAutomated', { player: closeReason.playerName })}
                      </div> */}
                        </>
                      ) : (
                        <>
                          {closeReason && closeReason.playerUid === member.uid ? (
                            <div className="end-result-modal-close-reason">
                              {t('missedTurnMessageMe', { player: closeReason.playerName, amount: leavePenalty })}
                            </div>
                          ) : (
                            <div className="end-result-modal-close-reason">
                              {t('missedTurnMessageOther', { player: closeReason.playerName, amount: leaveReward })}
                            </div>
                          )}
                          {/*  <div className="end-result-modal-close-reason">
                        {t('missedTurnMessage', { player: closeReason.playerName })}
                      </div> */}
                        </>
                      )}
                    </>
                  ) : (null)}
                  {(closeReason && closeReason.reason === 'leftRoom') ? (
                    <>
                      {closeReason.playerUid === member.uid ? (
                        <div className="end-result-modal-close-reason">
                          {t('leftRoomMe', { player: closeReason.playerName, amount: leavePenalty })}
                          {(tournamentRoom) ? (
                            <>
                              {' '}
                              {tournamentRound === 'last' ? (t('lastRoundTournamentLast')) : (t('lastRoundTournament', { round: tournamentRound || 1 }))}
                            </>
                          ) : (null)}
                        </div>
                      ) : (
                        <div className="end-result-modal-close-reason">
                            {t('leftRoomOther', { player: closeReason.playerName, amount: leaveReward })}
                            {(tournamentRoom) ? (
                              <>
                                {' '}
                                {tournamentRound === 'last' ? (t('lastRoundTournamentLast')) : (t('lastRoundTournament', { round: tournamentRound || 1 }))}
                              </>
                            ) : (null)}
                        </div>
                      )}

                      {/*  <div className="end-result-modal-close-reason">
                      {t('leftRoom', { player: closeReason.playerName })}
                    </div> */}
                    </>
                  ) : (null)}
                </div>
              </Fragment>
            )}
            {fourthPlayerMissed ? (
              <div className="end-result-modal-close-reason">
                {t('missedTurn', { player: member.name })}
              </div>
            ) : (null)}
          </ModalBody>
          <ModalFooter className="end-result-modal-footer">
            <Button color="link" className="end-result-modal-footer-button" onClick={() => { playButtonSound(); this.setShowEndResultModal(false); }}>
              {gameT('ok')}
            </Button>
          </ModalFooter>
        </Modal>

        <Modal container={'div > div'} isOpen={showPartyModal} toggle={this.closePartyModal} className={`old-modal root-font-${fontMode}`} style={{maxWidth: players && players.player4 ? '785px' : '645px'}}>
          <ModalHeader
            toggle={this.closePartyModal}
            close={
              <Media src={closeImg} className="notification-header-close" alt="X" onClick={this.closePartyModal} />
            }
          >{t('game:gameDetails')}</ModalHeader>
          {console.log('sortedPartyLog', { sortedPartyLog })}
          <ModalBody className="end-result-party-modal">
            {(partyLog && partyLog.length) ? (
              <>
                <ScrollAreaButtons
                  scrollPos={scrollPos}
                  show={this.pointsScrollbar && this.pointsScrollbar.state && this.pointsScrollbar.state.realHeight >= this.pointsScrollbar.state.containerHeight ? true : null}
                  scroll={this.scroll}
                  speed={30}
                  rightOffset={15}
                  topOffset={3}
                  bottomOffset={8}
                  bgColor="#2c5955"
                />
                {/*  <ScrollAreaButtons
                  scrollPos={scrollPos}
                  scroll={this.scroll}
                  speed={30}
                /> */}
                <ScrollArea
                  speed={0.55}
                  className="score-table-scroll-area"
                  contentClassName="score-table-scroll-content score-table-fourPlayer-scroll-content"
                  verticalContainerStyle={{
                    background: 'transparent',
                    opacity: 1,
                    width: 11,
                    marginRight: 2,
                  }}
                  verticalScrollbarStyle={{
                    background: '#fff',
                    borderRadius: 1,
                    width: 10,
                  }}
                  style={{ height: 250, paddingRight: 12 }}
                  minScrollSize={25}
                  horizontal={false}
                  onScroll={this.updateScrollPos}
                  ref={(el) => {
                    this.pointsScrollbar = el;
                  }}
                >
                  {sortedPartyLog.map((logData) => {
                    if (logData.key === 'gameResult') {
                      return null;
                    }
                    if (!logData.type) {
                      return null;
                    }
                    if (logData.type === 'endRoomPules' || logData.type === 'playerLeft' || logData.type === 'playerJoined' || logData.type === 'roundOver' || logData.type === 'setLast') {
                      return null;
                    }

                    const data = logData.data || {};

                    let hasDivider = false;

                    if (logData.type === 'determineStrongest' || ((logData.type === 'selectType' || logData.type === 'selectTypeAutomated') && data.type !== 'parasta' && data.type !== 'garam')
                      || logData.type === 'burriedCard' || logData.type === 'burriedCardAutomated' || logData.type === 'roundResult') {
                      if ((logData.type === 'burriedCard' || logData.type === 'burriedCardAutomated') && !secondBurriedCard) {
                        secondBurriedCard = true;
                      } else {
                        hasDivider = true;
                      }
                    }

                    if ((logData.type === 'selectType' || logData.type === 'selectTypeAutomated') && data.type === 'garam' && data.action === 'galdins') {
                      hasDivider = true;
                    }

                    else if (logData.type === 'updatePoints' || logData.type === 'roomClosed') {
                      hasDivider = true;
                    }

                    let selectTypeText;

                    if (logData.type === 'selectType' || logData.type === 'selectTypeAutomated') {
                      if (data.type === 'parasta') {
                        selectTypeText = t('game:regular');
                      } else if (data.type === 'zole') {
                        selectTypeText = t('selectZole');
                      } if (data.type === 'maza') {
                        selectTypeText = t('selectSmZole');
                      }
                    }

                    let logString;

                    switch (logData.type) {
                      case 'cardsDealt':
                        logString = t('dealtCards');
                        // logString += `\n${logData.data.player1 ? (`${logData.data.player1.player}`) : ''}: ${logData.data.player1.cards ? logData.data.player1.cards : ''}`;
                        // logString += `\n${logData.data.player2 ? (`${logData.data.player2.player}`) : ''}: ${logData.data.player2.cards ? logData.data.player2.cards : ''}`;
                        // logString += `\n${logData.data.player3 ? (`${logData.data.player3.player}`) : ''}: ${logData.data.player3.cards ? logData.data.player3.cards : ''}`;
                        // if (logData.data.player4 && logData.data.player4.player) {
                        //   logString += `\n${logData.data.player4 ? (`${logData.data.player4.player}`) : ''}: ${logData.data.player4.cards ? logData.data.player4.cards : ''}`;
                        // }
                        // logString += `\n${logData.data.cardsOnTable && logData.data.cardsOnTable}`;
                        break;

                      case 'cardPlayed':
                      case 'cardPlayedAutomated':
                        if (players[data.player]) {
                          logString = `${players[data.player].name} ${t('playedCard')} ${data.playedCard}`;
                        }
                        break;

                      case 'burriedCard':
                      case 'burriedCardAutomated':
                        logString = `${t('burriedCardText', { burriedCard: data.burriedCard, newPnts: data.newPnts })}`;
                        break;

                      case 'selectType':
                            if (data.type === 'garam') {
                              if (data.action !== 'galdins') {
                                logString = t('passedText', {player: data.player})
                              } else {
                                logString = t('passedTextGaldins', {player: data.player})
                              }
                            } else {
                              if (data.type === 'zole') {
                                logString = `${t('player')} ${data.player} ${t('selectZole')}`;
                              } else if (data.type === 'maza') {
                                logString = `${t('player')} ${data.player} ${t('selectSmZole')}`;
                              } else {
                                logString = t('tookTableText', { player: data.player });
                              }
                              // if (i18n.language === sendLanguage.lv) {
                              //   if (data.type === 'zole' || data.type === 'parasta') {
                              //     logString = `${t('player')} ${data.player} ${t('selectZole')}`;
                              //   } else if (data.type === 'maza') {
                              //     logString = `${t('player')} ${data.player} ${t('selectSmZole')}`;
                              //   } else if (data.type === 'regular') {
                              //     logString = `${t('player')} ${data.player} ${t('game:regular')}`;
                              //   }
                              // } else {
                              //   logString = `${t('player')} ${data.player} ${t('takeTable')}`;
                              // }
                            }
                          {/* {`${t('selectTypeText1', { player: action.data.player })} ${t(action.data.type)}`} */}
                          {/* {`${t('player')} ${action.data.player} paņēma galdu`} */}
                          {/* {action.data.type === 'garam' && action.data.action === 'next' && (' ')}
                          {action.data.type === 'garam' && action.data.action === 'galdins' && (`${t('galdinsPlayed')}`)}
                          {action.data.type === 'garam' && action.data.action === 'nextRound' && (`${t('nextRoundPlayed')}`)} */}
                      case 'selectTypeAutomated':

                        if (data.type === 'garam') {
                          if (data.action !== 'galdins') {
                            logString = t('passedText', { player: data.player });
                          } else {
                            logString = t('passedTextGaldins', { player: data.player });
                          }
                        } else {
                            if (data.type === 'zole') {
                              logString = `${t('player')} ${data.player} ${t('selectZole')}`;
                            } else if (data.type === 'maza') {
                              logString = `${t('player')} ${data.player} ${t('selectSmZole')}`;
                            } else {
                              logString = t('tookTableText', { player: data.player });
                            }
                          // if (i18n.language === sendLanguage.lv) {
                          //   console.log(i18n.language, data.type, "current========")
                          //   if (data.type === 'zole' || data.type === 'parasta') {
                          //     logString = `${t('player')} ${data.player} ${t('selectZole')}`
                          //   } else if (data.type === 'maza') {
                          //     logString = `${t('player')} ${data.player} ${t('selectSmZole')}`
                          //   } else if (data.type === 'regular') {
                          //     logString = `${t('player')} ${data.player} ${t('game:regular')}`
                          //   } else {
                          //     logString = `${t('player')} ${data.player} ${t('game:takeTable1')}`
                          //   }
                          // } else {
                          //   logString = `${t('player')} ${data.player} ${t('game:takeTable1')}`
                          // }
                        }
                        break;

                      case 'determineStrongest':
                        logString = t('determineStrongestText', { card: data.card, winPlayer: data.winPlayer, tablePoints: data.tablePoints || '0' });
                        break;

                      case 'playerQuit':
                        logString = t('playerQuit2', {player: logData.data.player});
                        break;

                      case 'startAutomated':
                        logString = t('startAutomated', { player: data.playerName });
                        break;

                      case 'endAutomated':
                        logString = t('endAutomated', { player: data.playerName });
                        break;

                      case 'roomClosed':
                        if (data.type && data.type === 'lastRound') {
                          if (data.playerUid === 'tournament') {
                            if (tournamentRound === 'last') {
                              logString = t('lastRoundTournamentLastLog');
                            } else {
                              logString = t('lastRoundTournamentLog', { round: tournamentRound });
                            }
                          } else {
                            logString = t('lastRound', { player: data.player });
                          }
                        } else if (data.type && data.type === 'leftRoom') {
                          logString = t('leftRoom', { player: data.player });
                        } else if (data.type && data.type === 'endAutomatedRoom') {
                          logString = t('endAutomatedRoom', { player: data.player });
                        } else if (data.type && data.type === 'missedTurn') {
                          logString = t('missedTurnMessage', { player: data.player });
                        } else if (data.type === 'tournamentEnd') {
                          logString = t('lastRoundTournamentLog', { tournamentRound });
                        }
                        break;

                      case 'roundResult':
                        if (data.type === 'galdins') {
                          //  logString = `${data.winner} ${t('loses')} ${data.type}`;
                          logString = `${data.winner} ${t('lostGame')} ${t('galdins')} ${t('withPoints')} ${data.largeTricks} ${t('tricksPlural')} (+${data.largePoints})`;
                          // largePoints largeTricks
                        } else {
                          logString = `${t('largePlayer')} (${data.largePlayer}) ${data.winner === 'large' ? t('wonGame') : t('lostGame')}`;
                          if (data.scoreType === 'parasts') {
                            if (data.largePoints === 0 && data.winner === 'large') {
                              logString += ` ${t('mazoZoli')}`;
                            } else {
                              logString += ` ${t('withPoints')} ${data.largePoints} ${t('points4')}`;
                            }
                          } else if (data.scoreType === 'jani') {
                            logString += ` ${t('withJani')}  ${t('withPoints')} ${data.largePoints} ${t('points4')}`;
                          } else if (data.scoreType === 'bezstikis') {
                            logString += ` ${t('withBezstikis')}`;
                          }
                        }
                        break;

                      case 'newPule':
                        if (data.type) {
                          logString = t('commonPuleAdded');
                        } else {
                          logString = t('privatePuleAdded', { player: data.player });
                        }
                        break;

                      case 'puleChange':
                        if (data.changeTo === 'P-') {
                          logString = t('roundPuleChanged2', { game: data.party, pule: data.changeTo });
                        } else {
                          logString = t('roundPuleChangedPersonal', { game: data.party, pule: data.changeTo });
                        }
                        break;

                      case 'updatePoints':
                        if (data.pointsChange < 0) {
                          logString = t('updatePointsNegative', { player: data.playerName, points: Math.abs(data.pointsChange), balance: Math.abs(data.balChange) });
                        } else {
                          logString = t('updatePoints', { player: data.playerName, points: data.pointsChange, balance: data.balChange });
                        }
                        break;

                      case 'leftRoomPenalty':
                        let pointsChange = null;
                        let balChange = null;

                        let playersNames = {};
                        Object.keys(players).map((key) => {
                          playersNames[players[key].uid] = players[key].name;
                        });

                        if (data && data.fullData && data.playerUid) {
                          Object.keys(data.fullData).map((key) => {
                            const pointsData = data.fullData[key];
                            const playerName = playersNames[key];

                            if (pointsData) {
                              balChange = pointsData.change;
                              pointsChange = pointsData.newPoints - pointsData.oldPoints;
                            }

                            if ((data.pointsChange || pointsChange) < 0) {
                              logString = (logString || '') + `${t('updatePointsNegative', { player: playerName, points: Math.abs(data.pointsChange || pointsChange), balance: Math.abs(data.balChange || balChange) })} \n`;
                            } else {
                              logString = (logString || '') + `${t('updatePoints', { player: playerName, points: data.pointsChange || pointsChange, balance: data.balChange || balChange })}\n`;
                            }
                          });
                        }

                      /* case 'leftRoomPenalty':
                        console.log('leftRoomPenalty data', data);
                        let pointsChange = null;
                        let balChange = null;
                        if (data && data.fullData && data.playerUid) {
                          const pointsData = data.fullData[data.playerUid];

                          console.log('pointsData', pointsData);

                          if (pointsData) {
                            balChange = pointsData.change;
                            pointsChange = pointsData.newPoints - pointsData.oldPoints;

                            console.log('pointsChange', pointsChange, balChange);
                          }
                        }
                        if ((data.pointsChange || pointsChange) < 0) {
                          logString = t('updatePointsNegative', { player: data.playerName, points: data.pointsChange || pointsChange, balance: data.balChange || balChange });
                        } else {
                          logString = t('updatePoints', { player: data.playerName, points: data.pointsChange || pointsChange, balance: data.balChange || balChange });
                        } */
                        break;

                      default: break;
                    }

                    return (
                      <>
                        <Row className={hasDivider ? 'end-result-party-modal-divide' : ''}>
                          <Col sm="3">
                            <Moment format="HH:mm:ss" locale="lv">
                              {logData.time}
                            </Moment>
                          </Col>
                          <Col sm="9">
                            <div>
                              { renderTextWithLineBreaksAndSymbolColor(logString) }
                            </div>
                            {/*  {logData.type === 'cardsDealt' ? (
                              <div>
                                {t('dealtCards')}
                              </div>
                            ) : (null)}
                            {(players[data.player] && (logData.type === 'cardPlayed' || logData.type === 'cardPlayedAutomated')) ? (
                              <div>
                                {`${players[data.player].name} ${t('playedCard')} ${data.playedCard}`}
                              </div>
                            ) : (null)}
                            {(logData.type === 'burriedCard' || logData.type === 'burriedCardAutomated') ? (
                              <div>
                                {`${t('burriedCardText', { burriedCard: data.burriedCard, newPnts: data.newPnts })}`}
                              </div>
                            ) : (null)}
                            {(logData.type === 'selectType' || logData.type === 'selectTypeAutomated') ? (
                              <>
                              {data.type === 'garam' ? (
                                <div>
                                  {data.action !== 'galdins' ? (
                                    <div>
                                      {t('passedText', { player: data.player })}
                                    </div>
                                  ):(
                                    <div>
                                      {t('passedTextGaldins', { player: data.player })}
                                    </div>
                                  )}
                                </div>
                              ):(
                                <div>
                                  {t('selectTypeText', { player: data.player, type: selectTypeText })}
                                </div>
                              )}

                              </>
                            ) : (null)}
                            {logData.type === 'determineStrongest' ? (
                              <div>
                                {t('determineStrongestText', { card: data.card, winPlayer: data.winPlayer, tablePoints: data.tablePoints })}
                              </div>
                            ) : (null)}
                            {logData.type === 'playerQuit' ? (
                              <div>
                                {t('playerQuit')}
                              </div>
                            ) : (null)}
                            {logData.type === 'startAutomated' ? (
                              <div>
                                {t('startAutomated', { player: data.playerName })}
                              </div>
                            ) : (null)}
                            {logData.type === 'endAutomated' ? (
                              <div>
                                {t('endAutomated', { player: data.playerName })}
                              </div>
                            ) : (null)}
                            {logData.type === 'roomClosed' ? (
                              <div>
                                {(data.type && data.type === 'lastRound') && (
                                  <div>
                                    {t('lastRound', { player: data.player })}
                                  </div>
                                )}
                                {(data.type && data.type === 'leftRoom') && (
                                  <div>
                                    {t('leftRoom', { player: data.player })}
                                  </div>
                                )}
                                {(data.type && data.type === 'missedTurn') && (
                                  <div>
                                    {t('missedTurnMessage', { player: data.player })}
                                  </div>
                                )}
                              </div>
                            ) : (null)}
                            {logData.type === 'roundResult' ? (
                              <div>
                                {data.type === 'galdins' ? (
                                  <Fragment>
                                    {`${data.winner} ${t('loses')} ${data.type}`}
                                  </Fragment>
                                ):(
                                  <Fragment>
                                    {`${t('largePlayer')} (${data.largePlayer}) ${data.winner === 'large' ? t('wins') : t('loses')}`}
                                    {data.scoreType === 'jani' && (` ${t('withJani')}`)}
                                    {data.scoreType === 'bezstikis' && (` ${t('withBezstikis')}`)}
                                  </Fragment>
                                )}
                              </div>
                            ) : (null)}

                            {logData.type === 'newPule' ? (
                              <div>
                                {data.type ? t('commonPuleAdded') : t('privatePuleAdded', { player: data.player })}
                              </div>
                            ) : (null)}

                            {logData.type === 'puleChange' ? (
                              <div>
                                {`${data.party} ${t('roundPuleChanged')} ${data.changeTo}`}
                              </div>
                            ) : (null)}

                            {logData.type === 'updatePoints' ? (
                              <div>
                                {t('updatePoints', { player: data.playerName, points: data.pointsChange, balance: data.balChange })}
                              </div>
                            ) : (null)}


                            {(logData.type !== 'updatePoints' && logData.type !== 'puleChange' && logData.type !== 'newPule' && logData.type !== 'roomClosed'
                            && logData.type !== 'playerQuit' && logData.type !== 'roundResult' && logData.type !== 'cardsDealt'
                            && logData.type !== 'cardPlayed' && logData.type !== 'cardPlayedAutomated' && logData.type !== 'burriedCard' && logData.type !== 'burriedCardAutomated'
                            && logData.type !== 'selectType' && logData.type !== 'selectTypeAutomated' && logData.type !== 'determineStrongest') ? (
                              <div>
                                {logData.type}
                              </div>
                            ) : (null)}  */}
                          </Col>
                        </Row>
                        <Row>
                          { logData.type === 'cardsDealt' && (
                            <Col xs="12" className="py-2">
                              { Object.keys(players).map(key => {
                                if (key === 'playerList') { return null; }
                                return (
                                  <div key={key} className="end-result-party-modal-cards">
                                    <div className="end-result-party-modal-cards-info"> {/* right part, name and cards */}
                                      <div className="end-result-party-modal-cards-info-title">
                                        {players[key].name ? trimString(players[key].name, 14) : null}
                                      </div>
                                      <div className="end-result-party-modal-cards-info-card-container">
                                        {(logData.data[key] && logData.data[key].cards) ? logData.data[key].cards.map((cardKey, cardIndex) => {
                                          return (
                                            <div key={`${key}-${cardIndex}`} className={`end-result-party-modal-cards-info-card-container-wrapper end-result-party-modal-cards-info-card-container-wrapper-${cardIndex}`}>
                                              <Media src={getCardImage(cardKey)} className="end-result-party-modal-cards-info-card-container-wrapper-card" />
                                            </div>
                                          );
                                        }):(null)}
                                      </div>
                                    </div>
                                  </div>
                                );
                              })}

                              <div className="end-result-party-modal-cards-table">
                                <div className="end-result-party-modal-cards-info-table"> {/* right part, name and cards */}
                                  <div className="end-result-party-modal-cards-info-table-title">
                                    {t('previousRound:tableCards')}
                                  </div>
                                  <div className="end-result-party-modal-cards-info-card-container">
                                    {logData.data.cardsOnTable ? logData.data.cardsOnTable.map((cardKey, cardIndex) => {
                                      return (
                                        <div key={`cardsOnTable-${cardIndex}`} className={`end-result-party-modal-cards-info-card-container-wrapper end-result-party-modal-cards-info-card-container-wrapper-${cardIndex}`}>
                                          <Media src={getCardImage(cardKey)} className="end-result-party-modal-cards-info-card-container-wrapper-card" />
                                        </div>
                                      );
                                    }):(null)}
                                  </div>
                                </div>
                              </div>
                            </Col>
                          )}
                        </Row>
                      </>
                    );
                  })}
                </ScrollArea>
              </>
            ) : (null)}
          </ModalBody>
          <ModalFooter className="end-result-modal-footer">
            <Button color="link" className="end-result-modal-footer-button" onClick={this.closePartyModal}>
              {gameT('ok')}
            </Button>
          </ModalFooter>
        </Modal>

        <Modal container={'div > div'} isOpen={openMissedInfoModal} toggle={this.showInfoModal} className={`old-modal root-font-${fontMode}`} >
          <ModalHeader
            toggle={this.showInfoModal}
            close={
              <Media src={closeImg} className="notification-header-close" alt="X" onClick={this.showInfoModal} />
            }
          >{t('game:penaltyInfo')}</ModalHeader>
          <ModalBody className="end-result-party-modal">
            <ScrollAreaButtons
              scrollPos={scrollPos}
              show={this.pointsScrollbar && this.pointsScrollbar.state && this.pointsScrollbar.state.realHeight >= this.pointsScrollbar.state.containerHeight ? true : null}
              scroll={this.scroll}
              speed={30}
              rightOffset={15}
              topOffset={3}
              bottomOffset={8}
              bgColor="#2c5955"
            />
            <ScrollArea
              speed={0.55}
              className="score-table-scroll-area"
              contentClassName="score-table-scroll-content score-table-fourPlayer-scroll-content"
              verticalContainerStyle={{
                background: 'transparent',
                opacity: 1,
                width: 11,
                marginRight: 2,
              }}
              verticalScrollbarStyle={{
                background: '#fff',
                borderRadius: 1,
                width: 10,
              }}
              style={{ height: 250, paddingRight: 12 }}
              minScrollSize={25}
              horizontal={false}
              onScroll={this.updateScrollPos}
              ref={(el) => {
                this.pointsScrollbar = el;
              }}
            >
              <div className="end-result-party-log-modal">
                <LeaveRoomPenalty lang={i18n.language || sendLanguage.lv} />
              </div>
            </ScrollArea>
          </ModalBody>
          <ModalFooter className="end-result-modal-footer">
            <Button color="link" className="end-result-modal-footer-button" onClick={this.showInfoModal}>
              {gameT('ok')}
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}
// );

const mapStateToProps = state => ({
  players: state.game.players || {},
  partyLog: state.rooms.partyLog || [],
  tournamentRoom: state.game.globalParams.tournamentRoom || null,
});

const mapDispatchToProps = {
  fetchPartyLog: getPartyLog,
  setRoomEndedFunction: setRoomEnded,
  setTournamentRoomEndedFunction: setTournamentRoomEnded,
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation(['notifications', 'game'])(withRouter(EndResultModal)));
