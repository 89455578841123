import { getBaseOs } from 'react-native-device-info';
import {
  isAndroid,
  isIOS,
  isIOS13,
  osName,
  osVersion,
} from 'react-device-detect';
import { Firebase, FirebaseRef, TournamentsRef } from '../lib/firebase';

export function getMyTournamentsData() {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  const UID = (
    FirebaseRef
    && Firebase
    && Firebase.auth()
    && Firebase.auth().currentUser
    && Firebase.auth().currentUser.uid
  ) ? Firebase.auth().currentUser.uid : null;

  if (!UID) return () => new Promise(resolve => resolve());

  return dispatch => new Promise(resolve => TournamentsRef().child(`playerActiveTournamentData/${UID}`)
    .on('value', (snapshot) => {
      const playerData = snapshot.val() || {};

      resolve(dispatch({
        type: 'MY_TOURNAMENTS_DATA',
        data: playerData,
      }));
    }));
}


export function getTournaments(devMode) {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  const UID = (
    FirebaseRef
    && Firebase
    && Firebase.auth()
    && Firebase.auth().currentUser
    && Firebase.auth().currentUser.uid
  ) ? Firebase.auth().currentUser.uid : null;

  if (!UID) return () => new Promise(resolve => resolve());

  return dispatch => new Promise(resolve => TournamentsRef().child('newTournamentsList')
    .orderByChild('open')
    .equalTo(true)
    .on('value', (snapshot) => {
      let data = snapshot.val() || {};

      console.log('getTournaments', data);

      if (!devMode) {
        const dataFiltered = {};
        Object.keys(data).map((key) => {
          if (data[key] && (data[key].devTournament !== true || devMode)) {
            dataFiltered[key] = { ...data[key] };
          }
          return null;
        });

        data = { ...dataFiltered };
      }

      return resolve(dispatch({ type: 'TOURNAMENTS_REPLACE', data }));
    })).catch((err) => { console.log(err.message); });
}

export function getTournamentsHistory(devMode) {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  return dispatch => new Promise(resolve => TournamentsRef().child('newTournamentsList')
    .orderByChild('open')
    .equalTo(false)
    .once('value', (snapshot) => {
      let data = snapshot.val() || {};

      if (!devMode) {
        const dataFiltered = {};
        Object.keys(data).map((key) => {
          if (data[key] && (data[key].devTournament !== true || devMode)) {
            dataFiltered[key] = { ...data[key] };
          }
          return null;
        });

        data = { ...dataFiltered };
      }
      return resolve(dispatch({ type: 'TOURNAMENTS_HISTORY_REPLACE', data }));
    })).catch((err) => { console.log(err.message); });
}

export function getPlayerTournamentsHistory(uid) {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  return dispatch => new Promise(resolve => TournamentsRef().child(`playerTournamentHistory/${uid}`)
    .once('value', (snapshot) => {
      const data = snapshot.val() || {};
      return resolve(dispatch({ type: 'PLAYER_TOURNAMENTS_HISTORY_REPLACE', data }));
    })).catch((err) => { console.log(err.message); });
}


export function getTournamentPlayers(tournamentId) {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  return dispatch => new Promise((resolve) => {
    const promise1 = TournamentsRef().child(`newTournaments/${tournamentId}/players`).orderByChild('quitBeforeStart').equalTo(null)
      .once('value');
    const promise2 = TournamentsRef().child(`newTournaments/${tournamentId}/playersStaticData`).orderByChild('quitBeforeStart').equalTo(null)
      .once('value');

    Promise.all([promise1, promise2]).then(async (promisesRes) => {
      let playersData;
      let playersStaticData;

      promisesRes.map((promiseRes, index) => {
        if (index === 0) {
          playersData = promiseRes.val() || {};
        } else if (index === 1) {
          playersStaticData = promiseRes.val() || {};
        }
        return null;
      });

      const players = {};

      if (playersStaticData) {
        await Promise.all(Object.keys(playersStaticData).map(async (key) => {
          await FirebaseRef.child(`users/${playersStaticData[key].uid}/bal`)
            .once('value', (snapshot) => {
              const balance = snapshot.val() || 0;

              players[key] = { ...playersData[key], ...playersStaticData[key], balance };
              return null;
            });
        }));
      }
      // console.log(playersData,playersStaticData, {players}, "tournaments players");
      return resolve(dispatch({ type: 'TOURNAMENT_PLAYERS_REPLACE', data: players }));
    });
  }).catch((err) => { console.log(err.message); });
}

export function getTournamentActiveRooms(tournamentId) {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  return dispatch => new Promise((resolve) => {
    const promise1 = TournamentsRef().child(`newTournaments/${tournamentId}/currentPlayedRooms`).orderByChild('roomClosed').equalTo(false)
      .once('value');

    Promise.all([promise1]).then((promisesRes) => {
      let currentPlayedRooms;

      promisesRes.map((promiseRes, index) => {
        if (index === 0) {
          currentPlayedRooms = promiseRes.val() || {};
        }
        return null;
      });

      //  console.log('currentPlayedRooms', { currentPlayedRooms });

      return resolve(dispatch({ type: 'TOURNAMENT_CURRENT_ROOMS_REPLACE', data: currentPlayedRooms, tournamentId }));
    });
  }).catch((err) => { console.log(err.message); });
}

export function closeTournamentResults() {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  const UID = (
    FirebaseRef
    && Firebase
    && Firebase.auth()
    && Firebase.auth().currentUser
    && Firebase.auth().currentUser.uid
  ) ? Firebase.auth().currentUser.uid : null;

  if (!UID) return () => new Promise(resolve => resolve());

  return () => new Promise((resolve) => {
    FirebaseRef.child(`users/${UID}/showTournamentResults`).remove().then(() => resolve()).catch(() => resolve());
  }).catch((err) => { console.log(err.message); });
}


/*
export function getTournamentPlayerHistory(tournamentId, playerId) {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  return dispatch => new Promise(resolve => {

    TournamentsRef().child(`tournamentPlayerHistory/${tournamentId}/players/${playerId}`).once('value', snapshot => {
      const playerHistory = snapshot.val() || null;

      return resolve(dispatch({ type: 'TOURNAMENT_PLAYER_HISTORY', data: playerHistory }));
    });

  }).catch((err) => { console.log(err.message); });
} */

export function joinTournament(tournamentId, passString) {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  const UID = (
    FirebaseRef
      && Firebase
      && Firebase.auth()
      && Firebase.auth().currentUser
      && Firebase.auth().currentUser.uid
  ) ? Firebase.auth().currentUser.uid : null;

  if (!UID) return () => new Promise(resolve => resolve());

  return () => new Promise(resolve => getBaseOs().then((baseOs) => {
    /* let env;
    if (baseOs === 'Windows' || (window && window.document)) {
      env = 'web';
    } else if (isAndroid) {
      env = 'android';
    } else if (isIOS || isIOS13) {
      env = 'ios';
    } else {
      env = 'android';
    } */

    let env;
    if (baseOs === 'Windows' || (window && window.document)) {
      env = 'web';
    } else if (isAndroid) {
      env = 'android';
    } else if (isIOS || isIOS13) {
      env = 'ios';
    } else {
      env = 'android';
    }

    const osNameVal = osName;
    const osVersionVal = osVersion;

    if (osNameVal === 'Android') {
      env = 'android';
    } else if (osNameVal === 'iOS') {
      env = 'ios';
    } else if (osNameVal === 'Windows' || osNameVal === 'Linux') {
      env = 'web';
    }


    const joinTournamentFunction = Firebase.app().functions('europe-west1').httpsCallable('joinTournament');

    joinTournamentFunction({
      tournamentId,
      passString: passString || null,
      env,
      osName: osNameVal || null,
      osVersion: osVersionVal || null,
    }).then((result) => {
      const { data } = result;
      resolve(data);
    });
  }));
}

export function leaveTournament(tournamentId) {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  return dispatch => new Promise(resolve => Firebase.auth().onAuthStateChanged((user) => {
    if (user) {
      const leaveTournamentFunction = Firebase.app().functions().httpsCallable('leaveTournament');

      leaveTournamentFunction({
        tournamentId,
      }).then((result) => {
        resolve(dispatch({ type: 'LEAVE_TOURNAMENT', data: result }));
      });
    }
  }));
}

export function returnToTournament(tournamentId) {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  const UID = (
    FirebaseRef
      && Firebase
      && Firebase.auth()
      && Firebase.auth().currentUser
      && Firebase.auth().currentUser.uid
  ) ? Firebase.auth().currentUser.uid : null;

  if (!UID) return () => new Promise(resolve => resolve());

  return () => new Promise((resolve) => {
    const returnToTournamentFunction = Firebase.app().functions().httpsCallable('returnToTournament');

    returnToTournamentFunction({
      tournamentId,
    }).then((result) => {
      const { data } = result;
      resolve(data);
    }).catch((err) => {
      console.log(err);
      resolve();
    });
  });
}

export function pauseTournament(tournamentId) {
  console.log('pauseTournament', { tournamentId });
  if (Firebase === null) return () => new Promise(resolve => resolve());

  const UID = (
    FirebaseRef
      && Firebase
      && Firebase.auth()
      && Firebase.auth().currentUser
      && Firebase.auth().currentUser.uid
  ) ? Firebase.auth().currentUser.uid : null;

  if (!UID) return () => new Promise(resolve => resolve());

  return () => new Promise((resolve) => {
    const pauseTournamentFunction = Firebase.app().functions().httpsCallable('pauseTournament');

    pauseTournamentFunction({
      tournamentId,
    }).then((result) => {
      const { data } = result;
      resolve(data);
    });
  });
}
