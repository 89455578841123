import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
// import Moment from 'react-moment';

import { withTranslation } from 'react-i18next';

import { connect } from 'react-redux';
import classNames from 'classnames';

import { Helmet } from 'react-helmet';
import ReactGA from 'react-ga';

import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
import Button from 'reactstrap/lib/Button';
import Media from 'reactstrap/lib/Media';

import ScrollAreaWrapper from '../../../Components/ScrollAreaWrapper';

import MenuRow from '../../../Components/Components/MenuRow';

import Leaderboard from '../TournamentLeaderboard';
import CustomDate from '../../../Components/Components/CustomDate';

import CustomModal from '../../../Components/Components/Modal';
import TournamentTutorial from '../Components/TournamentTutorial';
import TournamentInfoMobile from '../Mobile/Components/TournamentInfo';

import tutorialImage from '../../../../../../images/icons/help2.svg';
import lightInfoImg from '../../../../../../images/redesign/light-mode/player/info.svg';

import {
    getPlayerTournamentsHistory,
    getTournamentPlayers,
    getTournamentsHistory,
} from '../../../../../../actions/tournaments';
import { SCREEN_MODE } from '../../../../../../constants/constants';
import { getTournamentType } from '../../../../../../utils/tournamentUtils';
import LandScapeLayoutMobileHeader from '../../Layout/Mobile/LandScapeLayoutMobileHeader';
import { setOpenTournamentsTutorial } from '../../../../../../actions/state';
import { Input, Label } from 'reactstrap';


class TournamentsHistory extends React.PureComponent {
    static propTypes = {
        tournaments: PropTypes.arrayOf(PropTypes.shape()),
        tournamentPlayers: PropTypes.shape(),
        uid: PropTypes.string,
        t: PropTypes.func.isRequired,
        changeTab: PropTypes.func.isRequired,
        fetchTournamentPlayers: PropTypes.func.isRequired,
        fetchTournamentsHistory: PropTypes.func.isRequired,
        fetchPlayerTournamentsHistory: PropTypes.func.isRequired,
        screenMode: PropTypes.string,
    }

    static defaultProps = {
        tournaments: [],
        tournamentPlayers: {},
        uid: null,
        screenMode: SCREEN_MODE.normal,
    }

    constructor(props) {
        super(props);
        this.state = {
            openModal: false,
            tournamentId: '',
            scrollPos: 0,
            showTournamentTutorial: false,
            tournamentInfoModalOpen: false,
            selectTournament: {},
            onlyParticipatedTournaments: false,
        };

        this.scrollRef = React.createRef();
    }

    componentDidMount() {
        const { fetchTournamentsHistory, fetchPlayerTournamentsHistory, uid } = this.props;

        fetchTournamentsHistory();
        fetchPlayerTournamentsHistory(uid);
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.onlyParticipatedTournaments !== this.state.onlyParticipatedTournaments) {
            if (this.scrollRef.current) {
                this.scrollRef.current.scrollTo({ top: 0, behavior: 'smooth' });
            }
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.openTournamentsTutorial) {
            this.toggleShowTournamentTutorial();
            this.props.setOpenTournamentsTutorialFunc(false);
        }
     }

    openModal = (id) => {
        this.setState({
            tournamentId: id,
            openModal: true,
        });
    }

    toggle = () => {
        this.setState(prevState => ({
            openModal: !prevState.openModal,
        }));
    }

    fetchTournamentPlayers = (tournamentId) => {
        const { fetchTournamentPlayers } = this.props;

        if (tournamentId) {
            fetchTournamentPlayers(tournamentId).then(() => {
                this.setState({ openModal: true });
            });
        }
    }

    updateScrollPos = (val) => {
        if (val.topPosition || val.topPosition === 0) {
            this.setState({ scrollPos: val.topPosition || 0 });
        }
        //  this.setState({ scrollPos: val.topPosition || 0 });
    }

    scroll = (newVal) => {
        this.tableScrollbar.scrollYTo(newVal);
    }

    toggleShowTournamentTutorial = () => {
        const { showTournamentTutorial } = this.state;

        if (!showTournamentTutorial) {
            ReactGA.event({
                category: 'Menu',
                action: 'Tournaments Help',
            });
        }

        this.setState(prevState => ({ showTournamentTutorial: !prevState.showTournamentTutorial }));
    }

    renderTournamentState = t => (
        <div className="tournament-state tournament-state-closed">
            {t('tournaments.closed')}
        </div>
    )

    openTournamentInfoModal = (selectId) => {
        const { tournaments } = this.props;

        this.setState({
            tournamentInfoModalOpen: true,
            selectTournament: { ...tournaments.filter(tournament => tournament.id === selectId)[0] }
        })
    }

    closeTournamentInfoModal = () => {
        this.setState({
            tournamentInfoModalOpen: false,
        })
    }
    
    toggleOnlyParticipatedTournaments = () => {
        this.setState(prevState => ({
            onlyParticipatedTournaments: !prevState.onlyParticipatedTournaments,
        }));
    }

    render() {
        const {
            t,
            playerTournaments,
            tournaments,
            uid,
            changeTab,
            tournamentPlayers,
            screenMode,
            toggle,
            hasActiveRegistration,
            activeTab,
            achievementsNotification,
            handleClickStart,
            OpenMobileDropdownModal,
            showUserSettingsModal
        } = this.props;

        const {
            openModal,
            tournamentId,
            scrollPos,
            showTournamentTutorial,
            selectTournament,
            tournamentInfoModalOpen,
            onlyParticipatedTournaments,
        } = this.state;

        const playerTournamentsKeys = Object.keys(playerTournaments);
        const tournamentsShown = onlyParticipatedTournaments ? tournaments.filter((tournament) => playerTournamentsKeys.includes(tournament.id)) : tournaments;

        return (
            <>
                <Helmet>
                    <title>
                        Zole - {t('route.tournaments')}
                    </title>
                </Helmet>
                <div className="layout-mobile-body layout-mobile-body-tournaments-tab layout-mobile-body-tournaments-page-history">
                    <div className="layout-mobile-body-top">

                        {/* <Row className="layout-mobile-subheader d-none"> */}
                            {/* <div className='tournament-subheader-wrapper'>
                                <div className="layout-subheader-link">
                                    <Button color="link" className="layout-subheader-link-text active-tournaments-button" onClick={() => changeTab('6')}>
                                        {t('tournaments.activeTournaments')}
                                    </Button>
                                </div>
                                <div className="layout-subheader-link">
                                    <Button color="link" className="layout-subheader-link-text future-tournaments-button" onClick={() => changeTab('16')}>
                                        {t('tournaments.futureTournaments')}
                                    </Button>
                                </div>
                                <div className="layout-subheader-link">
                                    <Button color="link" className="layout-subheader-link-text past-tournaments-button layout-subheader-link-text-active" onClick={() => changeTab('7')}>
                                        {t('tournaments.tournamentHistory')}
                                    </Button>
                                </div>
                                <div className="layout-subheader-link">
                                    <div className='tournament-tutorial-button-wrapper-menu'>
                                        <Media className="tournament-header-tutorial-menu" style={{}} src={screenMode === SCREEN_MODE.light ? lightInfoImg : tutorialImage} onClick={() => this.toggleShowTournamentTutorial()} />
                                    </div>
                                </div>
                            </div> */}
                            {/* <div className='tournament-tutorial-button-wrapper'>
                                <Media className="tournament-header-tutorial" src={screenMode === SCREEN_MODE.light ? lightInfoImg : tutorialImage} onClick={() => this.toggleShowTournamentTutorial()} />
                            </div> */}
                        {/* </Row> */}
                    </div>

                    <div className="layout-mobile-body-main">
                        <div class="layout-mobile-body-main-title">{t('tournaments.tournamentHistory')}</div>

                        <div className="tournaments-filter" style={{paddingLeft: 15}}>
                            <Label check
                                style={{marginTop: 2, marginLeft: 20}}>
                                <Input
                                    type="checkbox"
                                    id="onlyParticipatedTournaments"
                                    name="onlyParticipatedTournaments"
                                    onChange={this.toggleOnlyParticipatedTournaments}
                                    checked={onlyParticipatedTournaments}
                                />
                                {t('tournaments.showParticipatedTournaments')}
                            </Label>
                        </div>
                        {(tournamentsShown.length === 0 && onlyParticipatedTournaments) ? (
                            <div className="tournaments-empty-msg-new h-100" style={{maxHeight: 'calc(100% - 55px)'}}>
                                {t('tournaments.noParticipatedTournaments')}
                            </div>
                        ) : (
                            <div
                                className="tournament-scrollarea"
                                contentClassName="tournament-scrollarea-body"
                                show
                                rightOffset={0}
                                topOffset={0}
                                bottomOffset={40}
                                ref={this.scrollRef}
                            >
                                {tournaments && tournaments.length > 0 && tournamentsShown.filter(tournament => tournament?.currentState !== 'play' && tournament?.currentState !== 'pause').map(tournament => {

                                    const { startDate, registerTime } = tournament;
                                    let registerDate;
                                    if (startDate && registerTime) {
                                        registerDate = startDate - (registerTime * 1000 * 60);
                                    }
                                    return (
                                        <MenuRow key={tournament.id}>
                                            <Row key={tournament.id} className="tournament-table-row">
                                                <div className='active-tournaments'>
                                                    <div className='active-tournaments-info'>
                                                        <div className='active-tournaments-info-section'>
                                                            <div className='active-tournaments-info-section-title'>
                                                                <div className='active-tournaments-info-section-title-wrapper'>
                                                                    <Media src={screenMode === SCREEN_MODE.light ? lightInfoImg : tutorialImage} onClick={() => this.openTournamentInfoModal(tournament.id)} className='active-tournaments-info-section-title-left-img' alt="x" />
                                                                    <div className='active-tournaments-info-section-inner-wrapper'>
                                                                        <div className='first-line'>
                                                                            <span className='active-tournaments-info-section-title-left-title'>{tournament.name}</span>
                                                                            <div className='active-tournaments-info-section-title-left-title-status'>{`${t('tournaments.closed')} `}</div>
                                                                        </div>
                                                                        <div className='second-line'>
                                                                            <span className='active-tournaments-info-section-title-left-private'>
                                                                                {getTournamentType(tournament, t)}
                                                                            </span>
                                                                            <div className='active-tournaments-info-section-time-rounds-wrapper'>
                                                                                <span className='active-tournaments-info-section-time-rounds'>{`${t('tournaments.rounds')}:`}</span>
                                                                                <span className='active-tournaments-info-section-time-roundsValue'>{tournament.rounds}</span>
                                                                            </div>
                                                                            <div className='active-tournaments-info-section-time-wrapper'>
                                                                                <span className='active-tournaments-info-section-time-label'>{`${t('tournaments.beginning')}:`}</span>
                                                                                <span className='active-tournaments-info-section-time-content'><CustomDate format="DD.MM.YYYY hh:mm" date={startDate} /></span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </div>
                                                        <div className='active-tournaments-info-button'>
                                                            <Button color="link" className="layout-default-button layout-float-right" onClick={() => this.fetchTournamentPlayers(tournament.id)}>
                                                                {t('tournaments.result')}
                                                            </Button>
                                                        </div>
                                                    </div>
                                                    <div className={
                                                        classNames('active-tournaments-sponsor', {
                                                            'active-tournaments-sponsor-two': tournament?.sponsorImg && tournament?.prizeImg
                                                        })
                                                    }>
                                                        {
                                                            tournament?.sponsorImg && (
                                                                <div className={
                                                                    classNames('active-tournaments-sponsor-section', {
                                                                        'active-tournaments-sponsor-section-two': tournament?.sponsorImg && tournament?.prizeImg
                                                                    })
                                                                }>
                                                                    <span>{t('tournaments.sponsorTournaments')}</span>
                                                                    <Media src={tournament?.sponsorImg} alt="x" />
                                                                </div>
                                                            )
                                                        }
                                                        {
                                                            tournament?.prizeImg && (
                                                                <div className={
                                                                    classNames('active-tournaments-sponsor-section', {
                                                                        'active-tournaments-sponsor-section-two': tournament?.sponsorImg && tournament?.prizeImg
                                                                    })
                                                                }>
                                                                    <span>{t('tournaments.mainPrize')}</span>
                                                                    <Media src={tournament?.prizeImg} alt="x" />
                                                                </div>
                                                            )
                                                        }
                                                    </div>
                                                </div>
                                            </Row>
                                        </MenuRow>
                                    );
                                })}
                            </div>
                        )}

                    </div>
                </div>

                <TournamentTutorial showTournamentTutorial={showTournamentTutorial} toggleShowTournamentTutorial={this.toggleShowTournamentTutorial} />
                <CustomModal
                    addClassName="tournaments-info-modal"
                    isOpen={tournamentInfoModalOpen}
                    toggle={this.closeTournamentInfoModal}
                    size="lg"
                    title={selectTournament?.name || t('tournaments.tournaments')}
                    footer={
                        <Button color="link" className="btn notification-footer-button" onClick={this.closeTournamentInfoModal}>
                            {t('common.close')}
                        </Button>
                    }
                    body={<TournamentInfoMobile t={t} selectTournament={selectTournament} />}
                />

                {uid && tournamentPlayers && openModal ? (
                    <>
                        <CustomModal
                            isOpen={openModal}
                            toggle={this.toggle}
                            size="lg"
                            addClassName="tournament-leaderboard-modal"
                            title={t('tournaments.complete')/*t('tournaments.top')*/}
                            footer={
                                <Button color="link" className="btn notification-footer-button" onClick={this.toggle}>
                                    {t('common.close')}
                                </Button>
                            }
                            /* <Button color="link" className="modal-footer-button" onClick={this.toggle}>{t('tournaments.close')}</Button> */
                            body={<Leaderboard tournament={selectTournament} leaderboard={tournamentPlayers} tournamentId={tournamentId} uid={uid} screenMode={screenMode} />}
                        />
                    </>
                ) : (null)}
            </>
        );
    }
}

const mapStateToProps = state => ({
    ignoredUsers: state.member.ignoredUsers || {},
    uid: state.member.uid || null,
    playerTournaments: (state.tournaments && state.tournaments.playerTournamentsHistory) ? state.tournaments.playerTournamentsHistory : {},
    tournaments: (state.tournaments && state.tournaments.tournamentsHistory) ? state.tournaments.tournamentsHistory : [],
    tournamentPlayers: (state.tournaments && state.tournaments.tournamentPlayers) ? state.tournaments.tournamentPlayers : {},
    openTournamentsTutorial: state.state.openTournamentsTutorial || false,
});

const mapDispatchToProps = {
    fetchTournamentPlayers: getTournamentPlayers,
    fetchPlayerTournamentsHistory: getPlayerTournamentsHistory,
    fetchTournamentsHistory: getTournamentsHistory,
    setOpenTournamentsTutorialFunc: setOpenTournamentsTutorial,

};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('common')(TournamentsHistory));
