import React, { Fragment, useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import Spinner from 'reactstrap/lib/Spinner';

import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { Helmet } from 'react-helmet';
import { useSelector, useDispatch } from 'react-redux';
import Moment from 'react-moment';

import { map, get, size, sortBy } from 'lodash';

import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
import Media from 'reactstrap/lib/Media';
import Button from 'reactstrap/lib/Button';
import { ModalBody, ModalHeader, Modal, ModalFooter } from 'reactstrap';

import ScrollAreaWrapper from '../../Components/ScrollAreaWrapper';

import PartyLog from './PartyLog';

import myInfoImg from '../../../../../images/icons/my_profile.webp';
import infoImg from '../../../../../images/redesign/player/info.svg';
import lightInfoImg from '../../../../../images/redesign/light-mode/player/info.svg';
// import defaultImage from '../../../../../images/redesign/common/default_image.svg';

import defaultImage from '../../../../../images/redesign/common/default_image.svg';
import normalDefaultImage from '../../../../../images/redesign/normal-mode/common/default_image.webp';
import lightDefaultImage from '../../../../../images/redesign/light-mode/common/default_image.svg';

import {
  getGameLogs,
} from '../../../../../actions/member';

import * as constants from '../../../../../constants/constants';
import ScrollAreaNew from '../../Components/ScrollAreaNew';

const GameLogs = ({ achievementsNotification, userSettings, uid, changeTab }) => {

  const [openPartyModal, setOpenPartyModal] = useState(false);
  const [party, setParty] = useState({});
  const [selectRoom, setSelectRoom] = useState(null);
  const [partyRoomData, setPartyRoomData] = useState({});
  const [countRoom, setCountRoom] = useState(0);
  const [roomPlayerData, setRoomPlayerData] = useState({});
  const [roomPlayerImgData, setRoomPlayerImgData] = useState({});

  const { t } = useTranslation('common');
  const dispatch = useDispatch();

  const gameLogs = useSelector(state => state.member.gameLogs);
  const isloading = useSelector(state => state.member.isloading);
  const screenMode = useSelector(state => state.member.screenMode);
  const fontMode = useSelector(state => state.member.fontMode);

  useEffect(() => {
    dispatch(getGameLogs());
  }, []);

  const changeTabA = (item) => {
    changeTab(item);
  };

  const togglePartyLog = (roomId, idx) => {
    setOpenPartyModal(true);
    setParty(gameLogs[roomId].roomData[idx + 1]);
    setSelectRoom(roomId);
    setPartyRoomData(gameLogs[roomId].roomData);
    setRoomPlayerData(gameLogs[roomId].playerData);
    setRoomPlayerImgData(gameLogs[roomId].playerImg);
  };

  const closePartyLog = () => {
    setOpenPartyModal(false);
    setParty({});
    setSelectRoom(null);
    setPartyRoomData({});
    setRoomPlayerData({});
    setRoomPlayerImgData({});
  };

  const totalNum = useMemo(() => {
    let count = countRoom;
    setCountRoom(count++);
    return (
      <div>
        {count++}
      </div>
    );
  }, [countRoom])

  const renderPlayerImg = (playerPhoto) => {
    if (playerPhoto) {
      return playerPhoto;
    } else {
      if (screenMode === constants.SCREEN_MODE.dark) return defaultImage;
      if (screenMode === constants.SCREEN_MODE.normal) return normalDefaultImage;
      if (screenMode === constants.SCREEN_MODE.light) return lightDefaultImage;
    }
  }

  return (
    <>
      <Helmet>
        <title>
          Zole - {t('myInfo.gameLogs')}
        </title>
      </Helmet>
      <div className="layout-body">
        <div className="layout-body-top">
          <Row className="layout-subheader">
            <div className="layout-subheader-link">
              <Button color="link" className="layout-subheader-link-text" onClick={() => changeTab(constants.MENU_NAVIGATION.myInfo)}>
                {t('common:myInfo.myInfo')}
              </Button>
            </div>
            <div className="layout-subheader-link">
              <Button color="link" className="layout-subheader-link-text" onClick={() => changeTab(constants.MENU_NAVIGATION.friends)}>
                {t('common:myInfo.friends')}
              </Button>
            </div>
            <div className="layout-subheader-link">
              <Button color="link" className="layout-subheader-link-text" onClick={() => changeTab(constants.MENU_NAVIGATION.ignoredUsers)}>
                {t('common:myInfo.ignoredPlayers')}
              </Button>
            </div>
            <div className="layout-subheader-link">
              <Button color="link" className={classNames("layout-subheader-link-text")} onClick={() => changeTab(constants.MENU_NAVIGATION.archievements)}>
                {t('common:myInfo.achievements')}
              </Button>
            </div>
            <div className="layout-subheader-link">
              <Button color="link" className="layout-subheader-link-text " onClick={() => changeTab(constants.MENU_NAVIGATION.gameHistory)}>
                {t('common:myInfo.results')}
              </Button>
            </div>
            <div className="layout-subheader-link">
              <Button color="link" className="layout-subheader-link-text layout-subheader-link-text" onClick={() => changeTab(constants.MENU_NAVIGATION.weeklyStatus)}>
                {t('common:myInfo.weeklyStatus')}
              </Button>
            </div>
            <div className="layout-subheader-link">
              <Button color="link" className="layout-subheader-link-text my-info-gameLogs-button layout-subheader-link-text-active" onClick={() => changeTab(constants.MENU_NAVIGATION.gameLogs)}>
                {t('common:myInfo.gameLogs')}
              </Button>
            </div>
          </Row>
        </div>
        <div className="layout-body-main">
          <div sm="12" className="new-game-logs">
            {
              isloading ? (
                <div className="top-table-spinner-loading-section d-flex align-items-center">
                  <Spinner color={screenMode === constants.SCREEN_MODE.light ? constants.SCREEN_MODE.dark : constants.SCREEN_MODE.light} style={{ width: '5rem', height: '5rem' }} />
                </div>
              ) : (
                gameLogs && size(gameLogs) > 0 ? (
                  <div
                    className="new-game-logs-scrollarea"
                  >
                    {
                      // map(sortBy(gameLogs, ['orderDate'], ['asc']), (item, key) => (
                      Object.keys(gameLogs).sort((a, b) => gameLogs[b].orderDate - gameLogs[a].orderDate).map((key, index) => (
                        <table className="new-game-logs-table mt-2" key={key}>
                          <thead>
                            <tr className="new-game-logs-table-header">
                              <th>#</th>
                              <th>{t('common.date')}</th>
                              <th>
                                <div className="new-game-logs-table-header-player d-flex flex-row align-items-center">
                                  <div className='top-table-row-player-image-frame'>
                                    <Media src={renderPlayerImg(gameLogs[key].playerImg[gameLogs[key]?.roomData[0]?.player1]?.photo)} className="top-table-row-player-image-default" alt="x" />
                                  </div>
                                  {gameLogs[key]?.roomData[0]?.player1 || ''}
                                </div>
                              </th>
                              <th>
                                <div className="new-game-logs-table-header-player d-flex flex-row align-items-center">
                                  <div className='top-table-row-player-image-frame'>
                                    <Media src={renderPlayerImg(gameLogs[key].playerImg[gameLogs[key]?.roomData[0]?.player2]?.photo)} className="top-table-row-player-image-default" alt="x" />
                                  </div>
                                  {gameLogs[key]?.roomData[0]?.player2 || ''}
                                </div>
                              </th>
                              <th>
                                <div className="new-game-logs-table-header-player d-flex flex-row align-items-center">
                                  <div className='top-table-row-player-image-frame'>
                                    <Media src={renderPlayerImg(gameLogs[key].playerImg[gameLogs[key]?.roomData[0]?.player3]?.photo)} className="top-table-row-player-image-default" alt="x" />
                                  </div>
                                  {gameLogs[key]?.roomData[0]?.player3 || ''}
                                </div>
                              </th>
                              {gameLogs[key].roomData[0]?.player4 && (
                                <th>
                                  <div className="new-game-logs-table-header-player d-flex flex-row align-items-center">
                                    <div className='top-table-row-player-image-frame'>
                                      <Media src={renderPlayerImg(gameLogs[key].playerImg[gameLogs[key]?.roomData[0]?.player4]?.photo)} className="top-table-row-player-image-default" alt="x" />
                                    </div>
                                    {gameLogs[key]?.roomData[0]?.player4 || ''}
                                  </div>
                                </th>
                              )}
                              <th>
                                {t('common.pules')}
                              </th>
                              <th />
                            </tr>
                          </thead>
                          <tbody>
                            {
                              gameLogs[key].roomsPoints && gameLogs[key].roomsPoints && Object.keys(gameLogs[key].roomsPoints.rounds).map((pointKey, index) => (
                                <>
                                  {gameLogs[key].roomData[index + 1] ? (
                                    <tr className="new-game-logs-table-row" key={pointKey}>
                                      <td>
                                        {index + 1}
                                      </td>
                                      <td>
                                        {/* {
                                          Object.keys(gameLogs[key].roomData[index + 1]).map((roundKey, roundIdx) => (
                                            roundIdx === 0 ? (
                                              <Moment format={constants.FORMAT_DATE_TYPE.fullDateMinute} locale={constants.sendLanguage.lv}>
                                                {gameLogs[key].roomData[index + 1][roundKey].time}
                                              </Moment>
                                            ) : (
                                              null
                                            )
                                          ))
                                        } */}
                                        {
                                          gameLogs[key].roomData[index + 1] ? (Object.keys(gameLogs[key].roomData[index + 1]).map((roundKey, roundIdx) => (
                                            roundIdx === 0 ? (
                                              <Moment format={constants.FORMAT_DATE_TYPE.fullDateMinute} locale={constants.sendLanguage.lv}>
                                                {gameLogs[key].roomData[index + 1][roundKey].time}
                                              </Moment>
                                            ) : (
                                              null
                                            )
                                          ))) : (null)
                                        }
                                      </td>
                                      <td>
                                        <div className="d-flex align-items-center width-100">
                                          <div className={classNames({
                                            'new-game-logs-table-row-large-trend': gameLogs[key].roomsPoints.rounds[pointKey].player1 > 0,
                                            'new-game-logs-table-row-low-trend': gameLogs[key].roomsPoints.rounds[pointKey].player1 < 0,
                                            'new-game-logs-table-row-neutral-trend': gameLogs[key].roomsPoints.rounds[pointKey].player1 === 0,
                                          })}
                                          >
                                            {gameLogs[key].roomsPoints.rounds[pointKey].player1}
                                          </div>
                                        </div>
                                      </td>
                                      <td>
                                        <div className="d-flex align-items-center width-100">
                                          <div className={classNames({
                                            'new-game-logs-table-row-large-trend': gameLogs[key].roomsPoints.rounds[pointKey].player2 > 0,
                                            'new-game-logs-table-row-low-trend': gameLogs[key].roomsPoints.rounds[pointKey].player2 < 0,
                                            'new-game-logs-table-row-neutral-trend': gameLogs[key].roomsPoints.rounds[pointKey].player2 === 0,
                                          })}
                                          >
                                            {gameLogs[key].roomsPoints.rounds[pointKey].player2}
                                          </div>
                                        </div>
                                      </td>
                                      <td>
                                        <div className="d-flex align-items-center width-100">
                                          <div className={classNames({
                                            'new-game-logs-table-row-large-trend': gameLogs[key].roomsPoints.rounds[pointKey].player3 > 0,
                                            'new-game-logs-table-row-low-trend': gameLogs[key].roomsPoints.rounds[pointKey].player3 < 0,
                                            'new-game-logs-table-row-neutral-trend': gameLogs[key].roomsPoints.rounds[pointKey].player3 === 0,
                                          })}
                                          >
                                            {gameLogs[key].roomsPoints.rounds[pointKey].player3}
                                          </div>
                                        </div>
                                      </td>
                                      {(gameLogs[key].roomData[0]?.player4) && (
                                        <td>
                                          <div className="d-flex align-items-center width-100">
                                            <div className={classNames({
                                              'new-game-logs-table-row-large-trend': gameLogs[key].roomsPoints.rounds[pointKey].player4 > 0,
                                              'new-game-logs-table-row-low-trend': gameLogs[key].roomsPoints.rounds[pointKey].player4 < 0,
                                              'new-game-logs-table-row-neutral-trend': gameLogs[key].roomsPoints.rounds[pointKey].player4 === 0,
                                            })}
                                            >
                                              {gameLogs[key].roomsPoints.rounds[pointKey].player4}
                                            </div>
                                          </div>
                                        </td>
                                      )}
                                      <td>
                                        {gameLogs[key].roomsPoints.rounds[pointKey].pule === 'P' && (
                                          <div> P </div>
                                        )}
                                        {gameLogs[key].roomsPoints.rounds[pointKey].pule === 'P-' && (
                                          <span className={`strikeout-new-${screenMode}`}> P </span>
                                        )}
                                        {gameLogs[key].roomsPoints.rounds[pointKey].pule === 'player1' && (
                                          <div>
                                            {gameLogs[key].roomData[0].player1}
                                          </div>
                                        )}
                                        {gameLogs[key].roomsPoints.rounds[pointKey].pule === 'player1-' && (
                                          <span className={`strikeout-new-${screenMode}`}>
                                            {gameLogs[key].roomData[0].player1}
                                          </span>
                                        )}
                                        {gameLogs[key].roomsPoints.rounds[pointKey].pule === 'player2' && (
                                          <div>
                                            {gameLogs[key].roomData[0].player2}
                                          </div>
                                        )}
                                        {gameLogs[key].roomsPoints.rounds[pointKey].pule === 'player2-' && (
                                          <span className={`strikeout-new-${screenMode}`}>
                                            {gameLogs[key].roomData[0].player2}
                                          </span>
                                        )}
                                        {gameLogs[key].roomsPoints.rounds[pointKey].pule === 'player3' && (
                                          <div>
                                            {gameLogs[key].roomData[0].player3}
                                          </div>
                                        )}
                                        {gameLogs[key].roomsPoints.rounds[pointKey].pule === 'player3-' && (
                                          <span className={`strikeout-new-${screenMode}`}>
                                            {gameLogs[key].roomData[0].player3}
                                          </span>
                                        )}
                                        {gameLogs[key].roomsPoints.rounds[pointKey].pule === 'player4' && (
                                          <div>
                                            {gameLogs[key].roomData[0].player4}
                                          </div>
                                        )}
                                        {gameLogs[key].roomsPoints.rounds[pointKey].pule === 'player4-' && (
                                          <span className={`strikeout-new-${screenMode}`}>
                                            {gameLogs[key].roomData[0].player4}
                                          </span>
                                        )}
                                      </td>
                                      <td className="new-game-logs-table-row-info">
                                        <Media src={screenMode === constants.SCREEN_MODE.light ? lightInfoImg : infoImg} alt="x" onClick={() => togglePartyLog(key, index)} />
                                      </td>
                                    </tr>
                                  ) : (null)}
                                </>
                              ))}
                          </tbody>
                        </table>
                      ))
                    }
                  </div>
                ) : (
                  <div className="d-flex align-items-center h-100 new-game-logs-empty-entries">
                    {t('home.noGameLog')}
                  </div>
                )
              )
            }
          </div>
        </div>
      </div>
      <PartyLog screenMode={screenMode} party={party} partyRoomData={partyRoomData} roomPlayerData={roomPlayerData} roomPlayerImgData={roomPlayerImgData} closePartyLog={closePartyLog} togglePartyLog={togglePartyLog} openPartyModal={openPartyModal} />
    </>
  );
};

GameLogs.propTypes = {
  achievementsNotification: PropTypes.bool,
  userSettings: PropTypes.shape({
    soundOn: PropTypes.bool,
  }),
  uid: PropTypes.string,
  changeTab: PropTypes.func.isRequired,
};

GameLogs.defaultProps = {
  userSettings: {},
  achievementsNotification: false,
  uid: null,
};

export default GameLogs;
